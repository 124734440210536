.c-edit-task {
    &__container {
        max-width: 400px;
        margin: 0 auto;
        text-align: left;
    }

    &__container-input {
        position: relative;
        margin-bottom: 15px;
    }

    &__container-billable {
        margin-top: 15px;
    }
}
