.o-add-button {
    position: relative;
    cursor: pointer;
    height: 24px;
    width: 24px;

    svg {
        fill: $color-white;
        transition: $transition-default;
    }

    @include mq(medium) {
        height: 32px;
        width: 32px;

        svg {
            fill: $color-grey-v2;
        }
    }

    &__main-icon {
        max-width: 100%;
        max-height: 100%;
    }

    &__plus-icon {
        position: absolute;
        top: 50%;
        right: -4px;
        margin-top: -5px;
        width: 10px;
        height: 10px;
        background: $color-blue-dark;
        border-radius: 50%;

        @include mq(medium) {
            right: -6px;
            margin-top: -7px;
            width: 14px;
            height: 14px;
            background: white;
        }
    }

    &:hover {
        svg {
            fill: $color-green;
        }
    }
}
