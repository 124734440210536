.c-reset-password {
    @include backdrop-header;

    &__form {
        margin: 0 auto;
        max-width: 310px;
        width: 100%;
    }

    &__form-password-icon {
        cursor: pointer;
        display: inline;
        position: absolute;
        right: 10px;
        top: 12px;
        opacity: 0.3;
        transition: $transition-default;

        &:hover {
            opacity: 1;
        }
    }

    &__form-btn {
        width: 100%;

        @include mq(tiny) {
            max-width: 150px;
        }
    }

    &__valid {
        color: $color-green;
    }

    &__invalid {
        color: $color-red;
    }
}
