.c-daily-hours {
    $c: &;
    text-align: left;

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -50px;
        padding: $global-spacing-unit $global-spacing-unit $global-spacing-unit *
            2;
    }

    &__header-avatar {
        > * {
            width: 60px;
            height: 60px;
            margin: 0 20px 0 0;
        }
    }

    &__header-name {
        line-height: 1.4;
        font-size: 24px;
    }

    &__title {
        line-height: 1.4;
        font-size: 24px;
    }

    &__row {
        display: flex;
        align-items: center;

        &--header {
            font-size: 14px;
            padding: $global-spacing-unit 0;
        }

        &:not(#{$c}__row--header) + & {
            margin-top: $global-spacing-unit;
            padding-top: $global-spacing-unit;
            border-top: 1px solid $border-color-default;
        }
    }

    &__col {
        &--range {
            flex: 3;
        }

        &--hours {
            flex: 1;
            padding-left: $global-spacing-unit * 2;

            #{$c}__row--header & {
                padding-left: $global-spacing-unit * 4;
            }
        }

        &--remove {
            width: $global-spacing-unit + 16px;
        }
    }

    &__range {
        display: flex;
        align-items: stretch;
        justify-content: center;
    }

    &__range-day {
        position: relative;
        flex: 1;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        color: $color-grey;
        cursor: pointer;

        &--active {
            color: $color-white;
            background-color: $color-green;
            border-radius: 10px;

            & + & {
                border-radius: 0 10px 10px 0;

                &:before {
                    content: "";
                    position: absolute;
                    background-color: $color-green;
                    width: 10px;
                    top: 0;
                    left: -10px;
                    bottom: 0;
                }
            }
        }
    }

    &__btn {
        background: #dfe1e4;
        border: 1px solid #d1d4d8;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 9px;
        text-align: center;
        text-transform: uppercase;
        margin-top: $global-spacing-unit * 2;
        cursor: pointer;

        &--reset {
            background-color: transparent;
        }
    }

    &__remove {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border: 1px solid $color-grey;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        margin-left: $global-spacing-unit;

        &:focus {
            outline: none;
        }

        #{$c}__row:last-of-type:first-of-type & {
            display: none;
        }
    }

    &__remove-icon {
        width: 6px;
        height: 6px;
        fill: $color-grey;
    }
}
