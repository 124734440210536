.c-add-task-row {
    display: none;
    background-color: #fff;
    padding: 4px 0 0;
    border-top: 1px solid $atr-border-default-color;

    @include mq(small) {
        display: flex;
    }

    > * {
        border-top: 1px solid $atr-border-default-color;
    }

    &__name-container {
        position: relative;
        display: flex;
        max-width: 30%;
        box-shadow: inset -8px 0 5px -5px rgba(0, 0, 0, 0.05);

        > task-dropdown {
            flex-grow: 1;
        }
    }

    &__btns-container {
        border-left: 1px solid $atr-border-default-color;
    }

    &__btns-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    [data-tooltip].u-tooltip--left:after,
    [data-tooltip].u-tooltip--right:after {
        min-width: 7em;
    }

    // Shame override

    .c-generic-dropdown:not(.c-generic-dropdown--contained) {
        height: 51px;

        .c-generic-dropdown__activator {
            position: relative;
            border: none;
            height: 51px;
            line-height: 51px;
            padding: 0 20px 0 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            background-color: transparent;

            &:focus {
                border: 1px solid $atr-border-focus-color;
                border-radius: 0;
            }
        }

        .c-generic-dropdown__activator-placeholder {
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 20px;
            background: transparent url($path-images + "/icon-search.png")
                no-repeat 0 center;
        }

        .c-generic-dropdown__activator-selected {
            position: absolute;
            top: 0;
            left: 14px;
            right: 20px;
            bottom: 0;
            line-height: 52px;
        }

        .c-generic-dropdown__activator-icon {
            display: none;
        }

        .c-generic-dropdown__dropdown {
            .c-generic-dropdown__input {
                border: none;
            }
        }

        .c-generic-dropdown__list-container {
            .c-timesheet--fixed-footer &,
            .c-timesheet--drop-up & {
                top: auto;
                bottom: calc(100% + 1px);
                box-shadow: 0 -3px 4px -1px $atr-shadow-drop-up-color;
            }
        }
    }

    &__task-start {
        opacity: 0.2;
        height: 51px;
        transition: $transition-default;

        .c-add-task-row:hover & {
            opacity: 1;
        }
    }

    &__btn-start-placeholder {
        display: inline-block;
        width: 45px;
        padding: 0 15px;
        cursor: default;
        background: transparent url($path-images + "/icons/play.svg") center
            no-repeat;
        background-size: 15px;
        opacity: 0.2;
    }

    &__seal {
        display: none;

        &--sealed {
            display: block;
            position: absolute;
            top: 0;
            left: -1px;
            right: 0;
            bottom: -1px;
            background: fixed
                repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 3px,
                    rgba($color-blue-darker, 0.2) 4px,
                    transparent 5px
                );
            transform-origin: left;
            transition: $transition-default;
            animation-name: sealShow;
            animation-duration: 0.3s;
        }
    }
}
