.u-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 30px;

    @include loader(30px);

    &--relative {
        display: block;
        position: relative;
        height: 30px;
    }
}
