.o-btn {
    display: block;
    width: 100%;
    margin-bottom: 0; // For input.o-btn
    font-weight: $btn-font-weight;
    font-family: $font-bold;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    @include button-size(
        $padding-base-vertical,
        $padding-base-horizontal,
        $font-size-base,
        $line-height-base,
        $btn-border-radius-base
    );
    @include user-select(none);
    box-shadow: 0 1px 2px 0 $btn-default-color;

    &:hover,
    &:focus,
    &--focus {
        color: $btn-default-color;
        text-decoration: none;
        outline: 0 !important;
    }

    &:active,
    &--active {
        outline: 0 !important;
        background-image: none;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    &--disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.65;
        box-shadow: none;
    }

    @include mq(tiny) {
        display: inline-block;
        width: auto;
    }
}

a.o-btn {
    &--disabled & {
        pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
}

// Alternate buttons
// --------------------------------------------------

.o-btn--default {
    @include button-variant(
        $btn-default-color,
        $btn-default-bg,
        $btn-default-border
    );
}

.o-btn--primary {
    @include button-variant(
        $btn-primary-color,
        $btn-primary-bg,
        $btn-primary-border
    );
}

.o-btn--success {
    @include button-variant(
        $btn-success-color,
        $btn-success-bg,
        $btn-success-border
    );
}

.o-btn--info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

.o-btn--warning {
    @include button-variant(
        $btn-warning-color,
        $btn-warning-bg,
        $btn-warning-border
    );
}

.o-btn--danger {
    @include button-variant(
        $btn-danger-color,
        $btn-danger-bg,
        $btn-danger-border
    );
}

.o-btn--google {
    @include button-variant(
        $btn-google-color,
        $btn-google-bg,
        $btn-google-border
    );
}

.o-btn--ms {
    @include button-variant($btn-ms-color, $btn-ms-bg, $btn-ms-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.o-btn--link {
    color: $link-color;
    font-family: $font-regular;
    font-weight: normal;
    border-radius: 0;
    text-transform: capitalize;

    &,
    &:active,
    &[disabled] {
        background-color: transparent;
        box-shadow: none;
    }

    &,
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }

    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
    }

    &[disabled] {
        &:hover,
        &:focus {
            color: $btn-link-disabled-color;
            text-decoration: none;
        }
    }
}

// Button Sizes
// --------------------------------------------------

.o-btn--large {
    @include button-size(
        $padding-large-vertical,
        $padding-large-horizontal,
        $font-size-base,
        $line-height-large,
        $btn-border-radius-large
    );
    padding: 10px 30px 9px;
}
.o-btn--small {
    @include button-size(
        $padding-small-vertical,
        $padding-small-horizontal,
        $font-size-small,
        $line-height-small,
        $btn-border-radius-small
    );
}
.o-btn--extrasmall {
    @include button-size(
        $padding-xs-vertical,
        $padding-xs-horizontal,
        $font-size-small,
        $line-height-small,
        $btn-border-radius-small
    );
}

// Block button
// --------------------------------------------------

.o-btn--block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.o-btn--block + .o-btn--block {
    margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.o-btn--block {
        width: 100%;
    }
}

.o-btn:not(.o-btn--block) + .o-btn:not(.o-btn--block) {
    margin-top: ($global-spacing-unit / 2);

    @include mq(tiny) {
        margin-top: 0;
        margin-left: ($global-spacing-unit / 2);
    }
}
