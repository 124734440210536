@mixin pricing-modal() {
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -50px;
        padding: $global-spacing-unit $global-spacing-unit $global-spacing-unit *
            2;
    }

    &__header-avatar {
        > * {
            width: 60px;
            height: 60px;
            margin: 0 20px 0 0;
        }
    }

    &__header-name {
        line-height: 1.4;
        font-size: 24px;
    }

    &__form {
        padding: $global-spacing-unit;
        margin: 0 $global-spacing-unit * -2;
        background: $color-grey-light;

        @include mq($until: small) {
            margin: 0 $global-spacing-unit * -1;
        }
    }

    &__form-container {
        margin: 0 auto;
        text-align: center;
        max-width: 400px;
    }

    &__form-rate {
        position: relative;

        & + & {
            margin-top: $global-spacing-unit;
        }
    }

    &__selected-payment-type {
        display: inline-block;
        position: relative;
        margin-bottom: $global-spacing-unit;
        cursor: default;
        font-weight: bold;
    }

    &__tabs {
        display: flex;
        justify-content: center;
        margin-bottom: $global-spacing-unit * 2;
    }

    &__tab {
        position: relative;
        display: inline-block;
        padding: 0 3px 2px;
        margin: 0 $global-spacing-unit;
        color: $color-grey;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 4px solid transparent;
        cursor: pointer;
        transition: $transition-default;

        &--active {
            color: $color-blue-dark;
            border-color: $color-green;
        }
    }

    @include validation;
}

@mixin pricing-history() {
    &__row,
    &__sub-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        border-bottom: 1px solid $border-color-default;
        padding: 10px 0;

        @include mq($until: tiny) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__sub-row {
        border-bottom: none;
        padding-right: 60px;

        &:last-of-type {
            border-bottom: 1px solid $border-color-default;
        }
    }

    &__container {
        position: relative;
        padding: 0 20px;
        margin: 0 -20px;
        background-color: $color-grey-light;

        &:before {
            content: "";
            position: absolute;
            top: -5px;
            right: 32.5%;
            width: 10px;
            height: 10px;
            transform-origin: center;
            transform: rotate(-45deg);
            background-color: $color-grey-light;
            border-top: 1px solid $border-color-default;
            border-right: 1px solid $border-color-default;

            @include mq($until: small) {
                display: none;
            }
        }

        @include mq(small) {
            padding: 0 40px;
            margin: 0 -40px;
        }
    }

    &__username {
        flex: 1;
    }

    &__amount {
        flex: 1;
        text-align: right;
        white-space: nowrap;

        &--wider {
            flex: 2;
        }

        @include mq($until: small) {
            margin-bottom: $global-spacing-unit / 2;
        }
    }

    &__overrides {
        flex: 1;
        cursor: pointer;
        text-align: right;

        @include mq($until: small) {
            margin-bottom: $global-spacing-unit / 2;
        }
    }

    &__period,
    &__period-edit {
        flex: 3;
        font-size: 90%;

        @include mq($until: small) {
            margin-bottom: $global-spacing-unit / 2;
        }
    }

    &__period-edit {
        margin: -2px 0;
    }

    &__action {
        flex-shrink: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        margin-left: 20px;
        opacity: 0.5;
        transition: $transition-default;

        &:hover {
            opacity: 1;
        }

        &--edit {
            background: url($path-images + "/icons/edit.svg") center no-repeat;
            background-size: 16px;
        }

        &--save {
            background: url($path-images + "/icons/save.svg") center no-repeat;
            background-size: 16px;
        }
    }

    &__photo {
        flex-shrink: 0;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $color-grey-light url($path-images + "/icons/user.svg")
            center no-repeat;
        background-size: 100%;
        box-shadow: 0 4px 4px 0 rgba($color-blue-darker, 0.2);
        transition: $transition-default;
        margin-right: $global-spacing-unit / 2;
    }

    &__photo-image {
        object-fit: cover;
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }
}
