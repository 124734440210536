.c-month-picker {
    display: block;
    position: relative;

    &__container {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 16px;
    }

    &__button-label {
        font-size: 10px;
        color: $color-grey-v5;
        margin-bottom: 4px;
    }

    &__button-value {
        color: $color-blue-dark;
    }

    &__popdown {
        position: absolute;
        top: 100%;
        top: calc(100% + 4px);
        right: 0;
        width: 340px;
        padding: $global-spacing-unit;
        background: $color-white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        z-index: 12;

        &--align-left {
            right: calc(100% * -1 + 1px);

            @include mq($until: small) {
                right: auto;
                left: 0;
            }
        }
    }

    &__popdown-years,
    &__popdown-months {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__popdown-years {
        margin-bottom: $global-spacing-unit;
    }

    &__popdown-year,
    &__popdown-month {
        width: 32%;
        text-align: center;
    }

    &__popdown-year {
        font-size: 20px;

        &--prev,
        &--next {
            cursor: pointer;
        }

        &--prev {
            text-align: right;
        }

        &--next {
            text-align: left;
        }
    }

    &__popdown-month {
        cursor: pointer;
        color: $color-blue-dark;
        font-weight: bold;
        padding: 8px 4px;
        margin: 2px 0;

        @include mq($until: small) {
            font-size: 12px;
        }

        &--current,
        &--selected,
        &:not(&--disabled):hover {
            color: #fff;
            border-radius: 8px;
        }

        &--current {
            background: $color-blue-dark;
        }

        &--selected {
            background: $color-green;
        }

        &--disabled {
            color: $color-grey;
            cursor: not-allowed;
        }

        &:not(&--disabled):hover {
            background: $color-green-dark;
        }
    }
}
