.c-ts-project-selector {
    flex-shrink: 0;
    display: block;
    position: relative;
    width: 6px;
    height: 51px;
    cursor: pointer;
    transition: $transition-default;

    &--active {
        width: 70% !important;
        max-width: 70% !important;
        border-right: 1px solid $ps-border-default-color;

        &:hover:before,
        &:hover:after,
        &:focus:before,
        &:focus:after {
            opacity: 0 !important;
        }

        & + * {
            width: 30% !important;
            max-width: 30% !important;
            transition: $transition-default;
        }
    }

    .c-add-task-row:hover &,
    .c-add-task-row--focus & {
        width: 32px;
    }

    &__project-name {
        display: block;
        padding-left: 6px;
        line-height: 51px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        font-family: $font-bold;
        transition: $transition-default;

        &:first-letter {
            color: #fff;
        }

        @include ts-project-name-colors;

        .c-ts-project-selector--active & {
            background: transparent;
        }

        .c-add-task-row:hover &,
        .c-add-task-row--focus & {
            padding-left: 9px;
        }

        .c-add-task-row:hover .c-ts-project-selector--active &,
        .c-add-task-row--focus & .c-ts-project-selector--active & {
            padding-left: 20px;
        }

        .c-ts-project-selector--active & {
            padding-left: 20px;
            padding-right: 20px;

            &:first-letter {
                color: inherit;
            }
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 51px;
        border: 0;
        padding: 0 35px 0 20px;
        font-family: $font-regular;
        transition: $transition-default;
        text-overflow: ellipsis;
        box-shadow: none;
        cursor: pointer;

        &:focus {
            outline: none;
            box-shadow: 0 5px 7px 1px rgba(41, 59, 77, 0.25);
        }

        .c-ts-project-selector--active & {
            opacity: 1;
            cursor: text;
        }
    }

    &__close {
        position: absolute;
        display: none;
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: 0;
        width: 33px;
        z-index: 111;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: 1px;
            width: 12px;
            height: 1px;
            background: $ps-text-default-color;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        .c-ts-project-selector--active & {
            display: block;
        }
    }

    &__project-list {
        position: absolute;
        top: 100%;
        left: -1px;
        right: -1px;
        background: #fff;
        border: 1px solid $ps-border-default-color;
        z-index: 100;
        box-shadow: 0 5px 7px 1px rgba(41, 59, 77, 0.25);
        transform: rotateX(90deg);
        transform-origin: top;
        transition: $transition-default;

        .c-ts-project-selector--active & {
            transform: rotateX(0);
        }

        .c-timesheet--fixed-footer &,
        .c-timesheet--drop-up & {
            top: auto;
            bottom: 100%;
            transform-origin: bottom;
            box-shadow: 0 -3px 4px -1px rgba(41, 59, 77, 0.25);
        }
    }

    &__project-list-ul {
        max-height: 260px;
        overflow-y: auto;
    }

    &__project-list-item {
    }

    &__project-list-item-link {
        display: block;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        height: 36px;
        line-height: 36px;
        padding: 0 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        outline: 0;

        &--keySelected {
            background-color: $ps-selected-option-bg-color !important;
        }

        @include ts-projects-list-colors;

        svg {
            max-width: 13px;
            max-height: 13px;
            vertical-align: text-top;
            opacity: 0.3;
            margin-left: 5px;

            * {
                fill: $default-text-color;
            }
        }
    }

    &__project-list-item-tooltip {
        text-transform: none;
        font-weight: normal;

        &:after {
            font-size: 12px !important;
        }
    }

    &__project-list-btn {
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 0 20px;
        color: $ps-text-default-color;
        font-family: $font-regular;
        background-color: transparent;
        border: none;
        border-top: 1px solid $ps-border-default-color;
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }
}
