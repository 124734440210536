@mixin projects-colors() {
    @each $property, $value in $projects-color-array {
        &--#{$property} {
            background-color: $value;
        }
    }
}

@mixin dropdown-projects-colors() {
    @each $property, $value in $projects-color-array {
        &--#{$property} {
            color: $value;

            .generic-dropdown-option,
            .c-generic-dropdown__option {
                color: $value;

                &:hover {
                    background-color: rgba($value, 0.15);
                }
            }
        }
    }
}

@mixin ts-project-name-colors() {
    @each $property, $value in $projects-color-array {
        &--#{$property} {
            color: $value;
            background: $value;
        }
    }
}

@mixin ts-projects-list-colors() {
    @each $property, $value in $projects-color-array {
        &--#{$property} {
            color: $value;

            &:hover,
            &.c-ts-project-selector__project-list-item-link--selected {
                background-color: rgba($value, 0.15);
            }
        }
    }
}
