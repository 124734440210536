/*------------------------------------*\
  #USER - page
\*------------------------------------*/

.c-user {
    width: 100%;

    &--not-searched {
        display: none;
    }

    // Role switcher
    // --------------------------------------------------

    &__switch-roles {
        display: flex;
        align-items: center;

        &--regular {
            display: none;

            .c-user:hover & {
                display: flex;
            }
        }
    }

    &__switch-list {
        display: flex;
        align-items: center;
        border: 1px solid rgba($default-text-color, 0.2);
        border-radius: 10px;
        overflow: hidden;
        margin-top: -2px;
        margin-right: $global-spacing-unit / 2;

        @include mq($until: large) {
            width: 100%;
            flex-direction: column;
            margin: $global-spacing-unit / 2;
        }
    }

    &__switch-role {
        transition: background-color 0.1s linear;

        &:hover {
            background-color: rgba($default-text-color, 0.1);
        }

        @include mq($until: large) {
            font-size: 16px;
            color: $default-text-color;
            line-height: 30px;
            text-align: left;
            cursor: pointer;
            width: 100%;
            padding: 0 $global-spacing-unit / 2;

            .c-user__switch-roles--current & {
                display: none;
            }

            &--active {
                background-color: rgba($default-text-color, 0.15);

                .c-user__switch-roles--current & {
                    display: block;
                    cursor: default;
                }
            }
        }

        @include mq(large) {
            font-size: 10px;
            width: 0;
            overflow: hidden;
            text-transform: uppercase;
            color: $default-text-color;
            line-height: 14px;
            cursor: pointer;

            .c-user:hover & {
                width: auto;
                padding: 0 10px;
            }

            .c-user__switch-roles--current & {
                display: none;
            }

            &--active {
                width: auto;
                padding: 0 10px;
                background-color: rgba($default-text-color, 0.15);

                .c-user__switch-roles--current & {
                    display: block;
                    cursor: default;
                }

                &:hover {
                    background-color: rgba($default-text-color, 0.15);
                }
            }
        }
    }
}
