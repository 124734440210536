.c-ts-user-header {
    &__alert {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 60px;
        color: #fff;
        font-size: 16px;
        background: $color-green;
        padding: 0 $global-spacing-unit 0 $global-spacing-unit * 2;
        z-index: 10;
    }
}
