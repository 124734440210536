@import "util"; // Mostly CSS3 properties
@import "vars"; // Pixel measurements, colors, fonts, etc.
@import "fade"; // Fade-in animation from animate.css
@import "buttons"; // Styles for different button types and states

div.hopscotch-bubble {
    background-color: $bubbleColor;
    border: $bubbleBorderWidth solid rgba($color-blue-dark, 0.8); /* default */
    font-family: $font-regular;
    color: $color-blue-darker;
    font-size: 13px;
    position: absolute;
    z-index: $bubbleZindex;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    @include box-sizing(content-box);

    * {
        @include box-sizing(content-box);
    }

    @include background-clip;

    &.animate {
        -moz-transition-property: top, left;
        -moz-transition-duration: $anim-duration;
        -moz-transition-timing-function: $anim-timing;

        -ms-transition-property: top, left;
        -ms-transition-duration: $anim-duration;
        -ms-transition-timing-function: $anim-timing;

        -o-transition-property: top, left;
        -o-transition-duration: $anim-duration;
        -o-transition-timing-function: $anim-timing;

        -webkit-transition-property: top, left;
        -webkit-transition-duration: $anim-duration;
        -webkit-transition-timing-function: $anim-timing;

        transition-property: top, left;
        transition-duration: $anim-duration;
        transition-timing-function: $anim-timing;
    }

    &.invisible {
        opacity: 0;
    }

    &.hide,
    .hide,
    .hide-all {
        display: none;
    }

    h3 {
        font-family: inherit;
        color: inherit;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        margin: -1px $bubblePadding 0 0;
        padding: 0;
    }

    .hopscotch-bubble-container {
        padding: $bubblePadding;
        position: relative;
        text-align: left;
        -webkit-font-smoothing: antialiased; /* to fix text flickering */
    }

    .hopscotch-content {
        font-family: $bubbleFontFamily;
        font-weight: normal;
        line-height: 17px;
        margin: -5px 0 11px;
        padding-top: 8px;
    }

    .hopscotch-bubble-content {
        margin: 0 0 0 ($circleWidth + 10px);
    }

    &.no-number .hopscotch-bubble-content {
        margin: 0;
    }

    .hopscotch-bubble-close {
        position: absolute;
        display: block;
        cursor: pointer;
        padding: 0;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
        background: transparent;
        border: none;
        color: #fff;
        font-size: 0;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: 0;
            margin-left: -6px;
            width: 12px;
            height: 2px;
            background: $color-blue-dark;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    .hopscotch-bubble-number {
        color: $color-green;
        display: block;
        float: left;
        font-size: 17px;
        font-weight: bold;
        line-height: $circleWidth + 1px;
        border: 2px solid $color-green;
        border-radius: 100%;
        padding: 0;
        margin-right: 10px;
        text-align: center;
        width: $circleWidth;
        height: $circleWidth;
    }

    .hopscotch-bubble-arrow-container {
        $arrowWidth: 17px;
        position: absolute;

        width: $arrowWidth * 2;
        height: $arrowWidth * 2;

        .hopscotch-bubble-arrow,
        .hopscotch-bubble-arrow-border {
            width: 0;
            height: 0;
        }

        &.up {
            top: 0 - $arrowWidth - $bubbleBorderWidth;
            left: $bubbleCornerRadius;

            .hopscotch-bubble-arrow {
                border-bottom: $arrowWidth solid $bubbleColor;
                border-left: $arrowWidth solid transparent;
                border-right: $arrowWidth solid transparent;
                position: relative;
                top: 0 - $arrowWidth + $bubbleBorderWidth + 2px;
            }
            .hopscotch-bubble-arrow-border {
                border-bottom: $arrowWidth solid rgba($color-blue-dark, 0.8);
                border-left: $arrowWidth solid transparent;
                border-right: $arrowWidth solid transparent;
            }
        }
        &.down {
            bottom: 0 - $arrowWidth * 2 - $bubbleBorderWidth;
            left: $bubbleCornerRadius;

            .hopscotch-bubble-arrow {
                border-top: $arrowWidth solid $bubbleColor;
                border-left: $arrowWidth solid transparent;
                border-right: $arrowWidth solid transparent;
                position: relative;
                top: 0 - $arrowWidth - $bubbleBorderWidth - 2px;
            }
            .hopscotch-bubble-arrow-border {
                border-top: $arrowWidth solid rgba($color-blue-dark, 0.8);
                border-left: $arrowWidth solid transparent;
                border-right: $arrowWidth solid transparent;
            }
        }
        &.left {
            top: $bubbleCornerRadius;
            left: 0 - $arrowWidth - $bubbleBorderWidth;

            .hopscotch-bubble-arrow {
                border-bottom: $arrowWidth solid transparent;
                border-right: $arrowWidth solid $bubbleColor;
                border-top: $arrowWidth solid transparent;
                position: relative;
                left: $bubbleBorderWidth + 2px;
                top: 0 - (2 * $arrowWidth);
            }
            .hopscotch-bubble-arrow-border {
                border-right: $arrowWidth solid rgba($color-blue-dark, 0.8);
                border-bottom: $arrowWidth solid transparent;
                border-top: $arrowWidth solid transparent;
            }
        }
        &.right {
            top: $bubbleCornerRadius;
            right: 0 - $arrowWidth * 2 - $bubbleBorderWidth;

            .hopscotch-bubble-arrow {
                border-bottom: $arrowWidth solid transparent;
                border-left: $arrowWidth solid $bubbleColor;
                border-top: $arrowWidth solid transparent;
                position: relative;
                left: 0 - $bubbleBorderWidth - 2px;
                top: 0 - (2 * $arrowWidth);
            }
            .hopscotch-bubble-arrow-border {
                border-left: $arrowWidth solid rgba($color-blue-dark, 0.8);
                border-bottom: $arrowWidth solid transparent;
                border-top: $arrowWidth solid transparent;
            }
        }
    }

    .hopscotch-actions {
        margin: 10px 0 0;
        text-align: right;
    }

    &:before {
        content: "";
        position: fixed;
        background: transparent;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    & > * {
        z-index: 2;
    }
}
