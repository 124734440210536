@mixin c-week-holiday(
    $bg-color,
    $optional-second-bg-color: "",
    $optional-third-bg-color: "",
    $opacity: 1
) {
    @if $optional-second-bg-color != "" and $optional-third-bg-color != "" {
        background: linear-gradient(
            to bottom,
            $bg-color 0%,
            $bg-color 33%,
            $optional-second-bg-color 33%,
            $optional-second-bg-color 66%,
            $optional-third-bg-color 66%,
            $optional-third-bg-color 100%
        );
    } @else if $optional-second-bg-color != "" {
        background: linear-gradient(
            to bottom,
            $bg-color 0%,
            $bg-color 50%,
            $bg-color 50%,
            $optional-second-bg-color 50%,
            $optional-second-bg-color 100%
        );
    } @else {
        background: $bg-color;
    }
    color: white;
    opacity: $opacity;
}

.c-week {
    &__day {
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        padding: 1px 0;

        &--sat,
        &--sun {
            color: $color-grey;
        }

        &--current-user-holiday {
            @include c-week-holiday($color-green);
        }

        &--current-user-sick-day {
            @include c-week-holiday($color-yellow);
        }

        &--holiday {
            @include c-week-holiday($color-blue);
        }

        &--overlapping-holidays {
            @include c-week-holiday($color-green, $color-blue);
        }

        &--sick-day {
            @include c-week-holiday($color-yellow);
        }

        &--overlapping-sick-day {
            @include c-week-holiday($color-green, $color-yellow);
        }

        &--overlapping-holiday-sick-day {
            @include c-week-holiday($color-blue, $color-yellow);
        }

        &--overlapping-user-holiday-sick-day {
            @include c-week-holiday($color-green, $color-blue, $color-yellow);
        }

        &--holiday-start {
            border-radius: 7px 0 0 7px;

            &.c-week__day--current-user-holiday-preselect,
            &.c-week__day--current-user-holiday-preselect-expanded-right,
            &.c-week__day--holiday-end-preselect {
                border-radius: 0;
            }
        }

        &--holiday-end {
            border-radius: 0 7px 7px 0;

            &.c-week__day--current-user-holiday-preselect,
            &.c-week__day--current-user-holiday-preselect-expanded-left,
            &.c-week__day--holiday-start-preselect {
                border-radius: 0;
            }
        }

        &--one-day-holiday {
            border-radius: 7px;

            &.c-week__day--current-user-holiday-preselect {
                border-radius: 0;
            }

            &.c-week__day--holiday-start-preselect {
                border-radius: 7px 0 0 7px;
            }

            &.c-week__day--holiday-end-preselect {
                border-radius: 0 7px 7px 0;
            }
        }

        &--current-user-holiday-preselect {
            @include c-week-holiday($color-green, "", "", 0.5);
        }

        &--current-user-holiday-preselect-expanded-left {
            @include c-week-holiday($color-green, "", "", 0.5);
        }

        &--current-user-holiday-preselect-expanded-right {
            @include c-week-holiday($color-green, "", "", 0.5);
        }

        &--one-day-holiday-preselect {
            @include c-week-holiday($color-green, "", "", 0.5);
            border-radius: 7px;

            &.c-week__day--holiday {
                border-radius: 0;
            }
        }

        &--one-day-holiday-preselect-between {
            @include c-week-holiday($color-green, "", "", 0.5);
            margin-right: -6px;
            padding-right: 6px;
            margin-left: -6px;
            padding-left: 6px;
        }

        &--holiday-start-preselect {
            @include c-week-holiday($color-green, "", "", 0.5);
            border-radius: 0;

            &:not(.c-week__day--holiday) {
                border-radius: 7px 0 0 7px;
            }
        }

        &--holiday-start-preselect-expanded {
            @include c-week-holiday($color-green, "", "", 0.5);
            border-radius: 7px 0 0 7px;
            margin-right: -6px;
            padding-right: 6px;
        }

        &--holiday-end-preselect {
            @include c-week-holiday($color-green, "", "", 0.5);
            border-radius: 0;

            &:not(.c-week__day--holiday) {
                border-radius: 0 7px 7px 0;
            }
        }

        &--holiday-end-preselect-expanded {
            @include c-week-holiday($color-green, "", "", 0.5);
            border-radius: 0 7px 7px 0;
            margin-left: -6px;
            padding-left: 6px;
        }
    }

    &__day-label {
        display: block;
        padding: 0 2px;

        .c-week__day--today & {
            color: #fff;
            background: $color-blue-dark;
            border-radius: 7px;
        }
    }
}
