.c-new-company {
    @include validation;

    &__form {
        margin: 0 auto;
        max-width: 310px;
        width: 100%;
    }

    &__form-name-error {
        margin: ($global-spacing-unit / -2) 0 $global-spacing-unit;
        font-size: small;
        color: $color-red;
    }

    &__form-password-input,
    &__form-name-input {
        @include input;
    }

    &__form-password-input {
        padding-right: $global-spacing-unit * 2;
    }

    &__form-password-icon {
        cursor: pointer;
        display: inline;
        position: absolute;
        right: 10px;
        top: 12px;
        opacity: 0.3;
        transition: $transition-default;

        &:hover {
            opacity: 1;
        }
    }

    &__form-log-in {
        margin: $global-spacing-unit 0;
        line-height: 1.5;
    }

    &__form-log-in-link {
        border-bottom: 1px solid $border-color-default;
    }

    &__subtitle {
        padding: ($global-spacing-unit / 2) 0 30px;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    &__subtitle-company {
        font-family: $font-bold;
        font-weight: normal;
        font-size: 5vw;
        margin-top: -25px;
        padding: 0 0 30px;

        @include mq(tiny) {
            font-size: 20px;
        }
    }

    &__subtitle-company-domain {
        color: $color-green;
    }

    &__form-btn {
        width: 100%;

        @include mq(tiny) {
            max-width: 150px;
        }
    }

    &__promo {
        display: block;
        font-size: small;
        cursor: pointer;
    }

    &__desc {
        color: $color-grey;
        display: inline-block;
        padding-left: 25px;
    }

    &__desc-link {
        border-bottom: 1px solid $border-color-default;
    }

    &__btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        > * {
            margin-bottom: 5px;
        }
    }

    &__email-btn {
        display: inline-block;
        width: auto;
        margin: 0 10px 5px;
        padding: 15px 20px 12px;
    }

    &__accept {
        text-align: left;
    }
}
