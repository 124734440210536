$tooltip-color-default: $color-blue-dark;
$tooltip-background-default: $color-blue-dark;
$tooltip-box-shadow-default: $box-shadow-default;
$tooltip-arrow-color: $color-blue-dark;
$tooltip-arrow-width: 10px;

[data-tooltip] {
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: 100;
        font-family: $font-regular;
        font-weight: normal;
        transition: $transition-default;
        transform: translate3d(0, 0, 0);
    }

    // Arrow tip base
    &:before {
        content: "";
        width: 0;
        border: 0.7em solid transparent;
        background: transparent;
        color: $tooltip-color-default;
        z-index: 101;
    }

    // Tooltip content base
    &:after {
        content: attr(data-tooltip);
        width: auto;
        max-width: 10em;
        padding: 0.6em 0.8em;
        background: $tooltip-background-default;
        box-shadow: $tooltip-box-shadow-default;
        font-size: 14px;
        color: #fff;
        text-align: center;
        white-space: pre-line;
        line-height: 1.2;
    }

    &:hover:before,
    &:hover:after,
    &:focus:before,
    &:focus:after {
        display: block;
        opacity: 1;
        visibility: visible;
    }

    @at-root .u-tooltip--sticky[data-tooltip],
        .u-tooltip--sticky[data-tooltip]:before,
        .u-tooltip--sticky[data-tooltip]:after {
        display: block;
        opacity: 1;
        visibility: visible;
    }

    // Top tooltip
    &.u-tooltip--top {
        &:before,
        &:after {
            bottom: calc(100% + 0.8em);
            left: 50%;
        }

        &:before {
            border-top-color: currentColor;
            margin-left: -0.65em;
            margin-bottom: -1.5em;
            transform: translateY(-0.03em);
        }

        &:after {
            transform: translateX(-50%);
        }
    }

    // Left tooltip
    &.u-tooltip--left {
        &:before,
        &:after {
            right: calc(100% + 1em);
            bottom: 50%;
        }

        &:before {
            border-left-color: currentColor;
            margin-right: -1.5em;
            margin-bottom: -0.65em;
        }

        &:after {
            min-width: 7em;
            transform: translateY(50%);
        }
    }

    // Right tooltip
    &.u-tooltip--right {
        &:before,
        &:after {
            left: calc(100% + 1em);
            bottom: 50%;
        }

        &:before {
            border-right-color: currentColor;
            margin-left: -1.5em;
            margin-bottom: -0.65em;
        }

        &:after {
            min-width: 6em;
            transform: translateY(50%);
        }
    }

    // Wide tooltip - as used in Your week for tasks
    &.u-tooltip--wide {
        cursor: default;

        &:after {
            width: 110%;
            min-width: 4em;
            max-width: 20em;
        }
    }

    &.u-tooltip--wider {
        cursor: default;

        &:after {
            width: max-content;
        }
    }

    &.u-tooltip--absolute {
        position: absolute;
    }

    &.u-tooltip--settings {
        &:after {
            width: 200px;
        }
    }

    &.u-tooltip--hide {
        &:before,
        &:after {
            opacity: 0;
        }
    }

    $transitionDelay: 1s;

    &.u-tooltip--hide-delay {
        &:before,
        &:after {
            transition-delay: $transitionDelay;
        }
    }

    &.u-tooltip--delay {
        &:before,
        &:after {
            transition-delay: 0ms;
        }
    }

    &.u-tooltip--delay {
        &:hover {
            &:before,
            &:after {
                transition-delay: $transitionDelay;
            }
        }
    }
}

//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 14px;
    opacity: 0;

    &.in {
        opacity: 1;
    }

    &.top {
        margin-top: -3px;
        padding: $tooltip-arrow-width 0;
    }

    &.right {
        margin-left: 3px;
        padding: 0 $tooltip-arrow-width;
    }

    &.bottom {
        margin-top: 3px;
        padding: $tooltip-arrow-width 0;
    }

    &.left {
        margin-left: -3px;
        padding: 0 $tooltip-arrow-width;
    }
}

// Wrapper for the tooltip content
.tooltip-inner {
    position: relative;
    z-index: 1071;
    max-width: 200px;
    padding: 6px 12px;
    color: #fff;
    text-align: center;
    background-color: $tooltip-background-default;
    border-radius: 0;
    box-shadow: $tooltip-box-shadow-default;
}

// Arrows
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    z-index: 1073;
}

.tooltip {
    &.top .tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-color;
    }

    &.top-left .tooltip-arrow {
        bottom: 0;
        right: $tooltip-arrow-width;
        margin-bottom: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-color;
    }

    &.top-right .tooltip-arrow {
        bottom: 0;
        left: $tooltip-arrow-width;
        margin-bottom: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
        border-top-color: $tooltip-arrow-color;
    }

    &.right .tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width $tooltip-arrow-width
            $tooltip-arrow-width 0;
        border-right-color: $tooltip-arrow-color;
    }

    &.left .tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -$tooltip-arrow-width;
        border-width: $tooltip-arrow-width 0 $tooltip-arrow-width
            $tooltip-arrow-width;
        border-left-color: $tooltip-arrow-color;
    }

    &.bottom .tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -$tooltip-arrow-width;
        border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        border-bottom-color: $tooltip-arrow-color;
    }

    &.bottom-left .tooltip-arrow {
        top: 0;
        right: $tooltip-arrow-width;
        margin-top: -$tooltip-arrow-width;
        border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        border-bottom-color: $tooltip-arrow-color;
    }

    &.bottom-right .tooltip-arrow {
        top: 0;
        left: $tooltip-arrow-width;
        margin-top: -$tooltip-arrow-width;
        border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
        border-bottom-color: $tooltip-arrow-color;
    }
}
