$datepiscker-footer-bg: $header-color-default;

.c-datepicker-footer {
    display: block;
    padding: ($global-spacing-unit / 2) $global-spacing-unit;
    margin: 0 ($global-spacing-unit * -1) ($global-spacing-unit / -2);
    background: $datepiscker-footer-bg;
    color: $color-blue-darker;

    &__list {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__list-item {
        cursor: pointer;
        width: 50%;
        height: 30px;
        display: inline-block;
        font-size: 14px;
        text-align: left;
        opacity: 0.4;
        transition: $transition-default;

        &.active,
        &:hover {
            opacity: 1;
        }

        @include mq(small) {
            width: 25%;
        }
    }
}

.range_inputs {
    display: none;
}
