.c-double-year-month-picker {
    display: flex;
    height: 100%;

    &__button {
        flex: 1 1 auto;

        @include mq($until: medium) {
            @include input;
            padding: 0;
            border-radius: 0;
            line-height: initial;
        }
    }
}
