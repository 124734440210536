@mixin filter-pill() {
    display: inline-block;
    margin: 10px 10px 0 0;
    padding: 0 0 0 10px;
    height: 30px;
    line-height: 30px;
    color: $color-blue-dark;
    font-size: 14px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba($color-grey, 0.45);

    &--readonly {
        padding-right: 10px;
    }

    &--invisibile {
        visibility: hidden;
    }
}

@mixin filter-pill-remove() {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0 0 0 7px;
    width: 30px;
    height: 30px;
    opacity: 0.3;
    transition: $transition-default;

    &:before,
    &:after {
        position: absolute;
        left: 16px;
        top: 7px;
        content: "";
        width: 1px;
        height: 14px;
        background: $color-blue-dark;
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover {
        opacity: 1;
    }
}
