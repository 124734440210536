.c-user-day-activity {
    display: block;
    position: relative;
    border-top: 1px solid #e8ebed;

    &:first-of-type {
        border-top: 0;
    }

    &__row {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #e8ebed;
    }

    &__subrow {
        position: relative;
        display: flex;
    }

    &__cell {
        min-height: 40px;

        &--first {
            margin-left: 20px;
            flex-shrink: 0;
            width: 20%;
        }

        &--second {
            flex-shrink: 0;
            width: 100px;
        }

        &--third {
            flex-shrink: 0;
            width: 100px;
        }

        &--first,
        &--second,
        &--third,
        &--fourth {
            display: flex;
            align-items: center;
        }

        &--fourth,
        &--wrapper {
            flex: 1;
        }
    }
}
