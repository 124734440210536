.c-add-task-row-explanation {
    display: block;
    position: relative;

    &__tip {
        position: absolute;
        top: 5px;
        padding-top: 200px;
        transform-origin: top;

        @include mq($until: small) {
            display: none;
        }

        &:before {
            content: "";
            position: absolute;
            height: 200px;
            width: 0;
            top: 0;
            border-left: 1px dashed lighten($color-grey, 15%);
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -4px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid lighten($color-grey, 15%);
        }

        &--1 {
            left: 5px;
            animation: slideIn 0.8s ease-in backwards;
        }

        &--2 {
            left: 15%;
            animation: slideIn 0.8s 0.2s ease-in backwards;
        }

        &--3 {
            left: 28%;
            animation: slideIn 0.8s 0.4s ease-in backwards;
        }

        &--4 {
            left: 55%;
            animation: slideIn 0.8s 0.6s ease-in backwards;
        }
    }

    &__tip-label {
        display: inline-block;
        border-top: 1px dashed lighten($color-grey, 15%);
        padding: 5px;
        font-size: 16px;
        color: $default-text-color;
    }

    &__tip-label-indicator {
        color: $color-green;
        font-weight: bold;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        max-width: 300px;
        margin-left: -150px;
        left: 50%;
        top: 300px;
        z-index: 40;
        animation: slideIn 0.8s 1s ease-in backwards;

        svg {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;

            * {
                fill: $color-green;
            }
        }

        @include mq($until: small) {
            display: none;
        }
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: rotateX(-60deg);
    }
    80% {
        opacity: 1;
        transform: rotateX(15deg);
    }
    100% {
        opacity: 1;
        transform: rotateX(0);
    }
}
