.c-call-to-action-modal {
    &__container {
        text-align: center;
    }

    &__or {
        display: block;
        margin: $global-spacing-unit 0;
    }

    &__label {
        display: none;

        @include mq(small) {
            display: inline;
        }
    }

    &__label-short {
        display: inline;

        @include mq(small) {
            display: none;
        }
    }
}
