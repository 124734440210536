@mixin add-entity {
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;

    &:hover:after {
        background-color: $color-blue-darker;
    }
}

@mixin add-entity-image {
    position: relative;
    padding: 0 0 14px 0;

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 5px;
        margin: 0 0 0 -25px;
        width: 50px;
        height: 50px;
        background: $color-blue-dark url($path-images + "/icon-add.png")
            no-repeat center center;
        border-radius: 100%;
        box-shadow: 0 2px 4px 0 rgba(45, 50, 53, 0.35);
    }
}

@mixin add-entity-image-icon {
    width: 104px;
    height: 104px;

    path,
    rect,
    polygon {
        fill: #e9ebed;
    }
}

@mixin add-entity-text {
    margin: 20px 0;
    font-size: 20px;
}

@mixin add-entity-mobile($top: 0, $left: 0) {
    position: fixed;
    top: $top;
    left: $left;
    width: $width-btn-header-tiny;
    height: $height-btn-add-mobile;
    display: block;
    z-index: 51;

    &:before,
    &:after {
        content: "";
        top: 50%;
        left: 50%;
        margin-left: -16px;
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: #fff;
    }

    &:before {
        transform: rotate(90deg);
    }
}
