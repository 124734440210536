/*------------------------------------*\
  #HEADER
\*------------------------------------*/

.c-main-header {
    @include mq(1px, medium) {
        margin: 0 $width-btn-header-tiny;
    }

    @include mq(1px, medium) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        text-align: center;
        justify-content: center;
        z-index: 50;
    }

    @include mq(medium) {
        width: 100%;
        height: $height-header;
        background-color: $header-color-default;
        position: relative;
        text-align: center;
        z-index: 0;
    }

    &__logo {
        @include mq(1px, medium) {
            display: inline-block;
            height: $height-header-mobile;
            width: 28px;
            margin-right: 10px;
            background: url($path-images + "/logo-sign.svg") no-repeat center;
            background-size: auto $height-logo;
        }

        @include mq(medium) {
            @include logo;
            display: block;
        }
    }

    &__title {
        position: relative;
        display: inline-block;
        line-height: $height-header-mobile;
        font-family: $font-bold;
        font-size: 16px;
        text-transform: none;
        text-align: center;
        color: #fff;
        margin: 0;
        font-weight: normal;

        &--with-underline:after {
            content: "";
            display: none;
            position: absolute;
            left: -10px;
            right: -10px;
            bottom: 0;
            height: 3px;
            background: $color-green;
        }

        @include mq(medium) {
            min-width: 200px;
            font-size: 24px;
            line-height: $height-header;
            text-transform: uppercase;
            color: $color-blue-darker;

            &--with-underline:after {
                display: block;
            }
        }
    }

    &__add-button {
        position: absolute;
        top: 0;
        left: -1 * $height-header-mobile;
        height: $height-header-mobile;
        width: $height-header-mobile;
        align-items: center;
        justify-content: center;
        display: flex;

        @include mq(medium) {
            left: auto;
            right: 0;
            height: $height-header;
            width: $height-header;
        }
    }
}
