.o-box {
    display: block;
    position: relative;
    background: #fff;
    margin: 0 auto ($global-spacing-unit * 2);
    padding: $global-spacing-unit;
    max-width: 650px;
    border-radius: 2px;
    text-align: center;

    &--wide {
        max-width: 880px;
    }

    &--thin {
        max-width: 420px;
    }

    &--grey {
        background-color: $header-color-default;
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: -13px;
        right: -13px;
        width: 36px;
        height: 36px;
        background: $color-blue-dark url($path-images + "/icon-close.svg")
            center no-repeat;
        border-radius: 18px;
        border: 2px solid $header-color-default;
        line-height: 50%;
        z-index: 100;
    }
}
