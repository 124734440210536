.c-user-rate-overrides {
    display: block;
    border-top: 1px solid $border-color-default;
    padding-top: $global-spacing-unit;

    &__btn-add {
        display: flex;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        svg {
            display: block;
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }
}
