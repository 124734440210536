.c-invitation-form {
    @include validation;

    &__form {
        @include thin-form;
    }

    &__form-input {
        @include input;

        background: #fff url($path-images + "/icon-user-green.png") no-repeat
            12px 12px;
        padding-left: $global-spacing-unit * 2;
        padding-right: $global-spacing-unit * 2;

        &--valid {
            background: #fff url($path-images + "/icon-check.svg") no-repeat
                12px 14px;
        }

        &--placeholder {
            background: #fff url($path-images + "/icon-user-gray.png") no-repeat
                12px 12px;
            cursor: text;
        }
    }

    &__form-remove {
        cursor: pointer;
        position: absolute;
        right: 1px;
        top: 1px;
        width: 40px;
        height: 40px;
        background: url($path-images + "/icon-close-gray.png") no-repeat center
            center;
    }
}
