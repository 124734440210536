/*------------------------------------*\
  #WIDTHS
\*------------------------------------*/

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include widths(3 4, -sm);

@mixin widths($widths-columns, $widths-breakpoint: null) {
    // Loop through the number of columns for each denominator of our fractions.
    @each $widths-denominator in $widths-columns {
        // Begin creating a numberator for our fraction up until we hit the
        // denominator.
        @for $widths-numerator from 1 through $widths-denominator {
            // Build a class in the format `.u-3/4` or `.u-3-of-4`.
            .u-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint} {
                width: ($widths-numerator / $widths-denominator) *
                    100% !important;
            }

            .u-push-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint} {
                position: relative;
                left: ($widths-numerator / $widths-denominator) *
                    100% !important;
            }

            .u-pull-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint} {
                position: relative;
                right: ($widths-numerator / $widths-denominator) *
                    100% !important;
            }
        }
    }
}

/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes can take a fraction-like format (e.g. `.u-2/3`) or a spoken-
 * word format (e.g. `.u-2-of-3`). Use these in your markup:
 *
 * <div class="u-7/12">
 */

@include widths(1 2 3 4 5);

@include mq($from: small) {
    @include widths(1 2 3 4 5 12, \@sm);
}

@include mq($from: medium) {
    @include widths(1 2 3 4 5 12, \@md);
}

@include mq($from: large) {
    @include widths(1 2 3 4 5 12, \@lg);
}

.u-flex-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ($global-spacing-unit / -2);

    > [class*="u-"] {
        position: relative;
        padding: 0 ($global-spacing-unit / 2);
    }

    > .u-1\/1:first-child:not(:last-child) {
        @include mq(1px, small) {
            margin-bottom: $global-spacing-unit;
        }
    }
}
