.c-project-modal {
    &__form {
        margin: 0 auto;
        text-align: left;
        max-width: 549px;
    }

    &__label {
        display: block;
        margin-bottom: 10px;
    }

    &__wrapper-selector {
        margin-bottom: 30px;
    }
}
