.c-ms-auth-button {
    display: inline-block;
    margin: 0 10px;

    &__error {
        margin-top: 10px;
        font-size: small;
        color: $color-red;
    }

    &__btn {
        width: 50px;
        padding: 12px 0 6px;

        svg {
            width: 24px;
            height: 24px;
            fill: #fff;
        }

        &.u-tooltip--top:after {
            text-transform: none;
            white-space: nowrap;
        }
    }
}
