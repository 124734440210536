.c-day-footer {
    text-align: center;
    color: $color-summary;
    font-size: 14px;

    @include mq(small) {
        &--weekend {
            min-width: 6%;
            flex-basis: 6%;
        }
    }
}
