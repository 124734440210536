.c-date-picker {
    &__container {
        position: relative;
    }

    &__input {
        @include input;

        &--start,
        &--end {
            padding: 0 0 0 34px;
            background: no-repeat 10px 12px;
        }

        &--start {
            background-image: url($path-images + "/icons/login.svg");
        }

        &--end {
            background-image: url($path-images + "/icons/logout.svg");
        }
    }

    &__hide {
        display: none;
    }

    &__flyout {
        position: absolute;
        top: 100%;
        top: calc(100% + 4px);
        right: 0;
        min-width: 100%;
        padding: $global-spacing-unit;
        background: $color-white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        z-index: 12;

        &.align-left {
            left: 0;
            right: auto;
        }
    }
}
