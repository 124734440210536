@mixin user-entry {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $global-spacing-unit;
    cursor: pointer;

    &__photo {
        position: relative;
        flex-shrink: 0;
        background-color: $color-grey-light;
        width: 54px;
        height: 54px;
        border-radius: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    &__photo-image {
        object-fit: cover;
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }

    &__name {
        margin-left: $global-spacing-unit;
    }
}
