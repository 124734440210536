.c-bar-chart {
    &__barchart {
        display: none;
        position: relative;
        width: 100%;
        padding-top: $global-spacing-unit;
        overflow: hidden;

        @include mq(medium) {
            display: block;
        }

        &--visible {
            overflow: visible;
        }

        &--with-transition {
            transition: $transition-default;
        }
    }

    &__chart {
        display: flex;
        flex-direction: column;
    }

    &__chart-container {
        flex: 1 0 auto;
        position: relative;
        margin-top: 30px;
    }

    &__chart-grid {
        margin: 0;
        padding: 0;
        width: calc(100% - 80px);
        height: 100%;

        &:before {
            bottom: -6px;
            right: -50px;
            padding: 0 0 0 16px;
            width: 50px;
            text-align: left;
            opacity: 0.5;
            font-size: 14px;
            line-height: 14px;
            font-weight: normal;
            color: $color-blue-dark;
        }
    }

    &__chart-grid-row {
        position: relative;
        height: 20%;
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
        color: $color-blue-dark;

        &--positive {
            border-top: 1px dashed $border-color-default;
        }

        &--negative {
            border-bottom: 1px dashed $border-color-default;
        }
    }

    &__base-line {
        display: flex;
        position: relative;

        &-divider {
            flex: 1 1 auto;
            border-bottom: 1px solid $border-color-default;
        }

        &-label-container {
            flex: 0 0 auto;
            padding: 0 0 0 15px;
            width: 80px;
            position: relative;
            top: -8px;
        }

        &-label {
            opacity: 0.5;
            position: absolute;
            top: 0;
            font-size: 14px;
            line-height: 14px;
            font-weight: normal;
        }
    }

    &__chart-grid-row-label {
        position: absolute;
        left: 100%;
        padding: 0 0 0 15px;
        width: 80px;
        text-align: left;
        opacity: 0.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--positive {
            top: -8px;
        }

        &--negative {
            bottom: -8px;
        }
    }

    &__chart-columns {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        width: calc(100% - 60px);
        height: 100%;
        text-align: center;
    }

    &__chart-columns-column {
        position: relative;
        float: left;
        width: 3.22%;
        height: 100%;
        min-width: 12px;
        text-align: center;
        list-style: none;

        // Currently chart display only 31 columns in row, we should hide other columns.
        &:nth-of-type(n + 32) {
            display: none;
        }
    }

    &__chart-columns-column-bar {
        position: absolute !important;
        left: 0;
        margin: 0 10%;
        width: 80%;
        transition: $transition-default;

        &--positive {
            bottom: 0;
            background-color: rgba($color-green, 0.9);

            .c-bar-chart__chart-columns-column:hover & {
                background-color: darken($color-green, 10);
            }
        }

        &--negative {
            top: 100%;
            bottom: auto;
            background-color: rgba($color-red, 0.9);

            .c-bar-chart__chart-columns-column:hover & {
                background-color: darken($color-red, 10);
            }
        }
    }

    &__chart-columns-column-value {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: -60px;
        padding: 10px 2px 0 2px;
        width: 100%;
        height: 60px;
        color: $color-grey;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        .c-bar-chart__chart-columns-column:hover & {
            color: $color-blue-dark;
        }
    }

    &__empty {
        width: 100%;
        line-height: 290px;
        text-align: center;
        font-size: 16px;
    }
}
