@mixin message {
    display: block;
    position: fixed;
    z-index: 1100;
    width: 100%;
    top: 0;
    left: 0;
    visibility: hidden;

    &--fade-out {
        animation-name: fade-out;
        animation-duration: 334ms;
        animation-direction: linear;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }

    &--fade-in {
        animation-name: fade-in;
        animation-duration: 334ms;
        animation-direction: linear;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
    }

    &--visible {
        visibility: visible;
    }

    &__container {
        padding: 0 $height-message 0 20px;

        &--error {
            color: #fff;
            background: $message-bg-error-color;
        }

        &--warning {
            color: $default-text-color;
            background: $message-bg-warning-color;
        }

        @include mq(small) {
            padding: 0 $width-menu 0 20px;
        }
    }

    &__desc {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: $height-message;
        line-height: 18px;
        font-family: $font-bold;
        font-size: 16px;
        overflow: hidden;
        padding: 5px 0;
    }

    &__close {
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        width: $height-message;
        height: $height-message;
        background-color: transparent;
        border: none;
        z-index: 100;

        &:before,
        &:after {
            position: absolute;
            content: "";
            background: $border-color-default;
            width: 16px;
            height: 2px;
            top: 24px;
            left: 50%;
            margin-left: -8px;
            transform-origin: center;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        @include mq(small) {
            width: $width-menu;
        }
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        visibility: visible;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
