.o-toggle-switch {
    display: inline-block;
    vertical-align: top;
    user-select: none;

    &__input {
        position: absolute;
        z-index: 1;
        width: 46px;
        margin: 0 0 0 -4px;
        height: 24px;
        opacity: 0;
        filter: alpha(opacity=0);
        cursor: pointer;
    }

    &__helper {
        display: inline-block;
        position: relative;
        height: 11px;
        width: 33px;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.26);
        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: -4px;
            width: 20px;
            height: 20px;
            background: #fafafa;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
            border-color: #c4cdd3;
            border-width: 0 0 1px 0;
            border-radius: 50%;
            transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }

        .o-toggle-switch__input:checked + &,
        &--checked {
            background: rgba(0, 0, 0, 0.26);

            &:before {
                left: 18px;
                background: #fff;
            }
        }

        &--checked {
            .o-toggle-switch--success & {
                background: $color-green;
            }
        }

        .o-toggle-switch--success .o-toggle-switch__input:checked + & {
            background: $color-green;
        }
    }
}
