.c-ts-week-changer {
    position: absolute;
    top: 0;
    cursor: pointer;
    font-size: 30px;
    width: 30px;
    height: $height-header;
    line-height: $height-header;
    text-align: center;

    &--prev {
        right: 4px;
    }

    &--next {
        left: -4px;
    }

    &__btn {
        display: block;
        color: $color-grey;
        transition: $transition-default;

        &:hover {
            color: $color-blue-darker;
        }
    }
}
