.c-login {
    @include backdrop-header;

    &__form {
        margin: 0 auto;
        max-width: 310px;
        width: 100%;
    }

    &__form-or {
        margin: -6px 0 12px 0;
    }

    &__form-password-input {
        @include input;
        padding-right: $global-spacing-unit * 2;
    }

    &__form-password-icon {
        cursor: pointer;
        display: inline;
        position: absolute;
        right: 10px;
        top: 12px;
        opacity: 0.3;
        transition: $transition-default;

        &:hover {
            opacity: 1;
        }
    }

    &__form-sign-up {
        margin: ($global-spacing-unit / 2) 0 $global-spacing-unit;
        line-height: 1.5;
    }

    &__form-link {
        border-bottom: 1px solid $border-color-default;
    }

    &__form-btn {
        width: 100%;

        @include mq(tiny) {
            max-width: 150px;
        }
    }

    &__login-error {
        font-size: small;
        color: $color-red;
    }

    &__g-ms-error {
        margin: 10px 0;
        font-size: small;
        color: $color-red;
    }
}
