.c-ts-day-changer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: $width-btn-header-tiny;
    min-width: $width-btn-header-tiny;
    vertical-align: middle;
    text-align: center;
    color: $color-summary;
    cursor: pointer;
    font-size: 30px;
    transition: color 300ms ease;

    &:hover {
        color: $color-blue-darker;
    }

    &--disabled {
        color: $border-color-default;
    }

    &--prev {
        right: 4px;
    }

    &--next {
        left: -4px;
    }

    &__btn {
        display: block;
        width: 100%;
        line-height: $height-header;
    }

    @include mq(tiny) {
        flex-basis: $width-btn-header-small;
        min-width: $width-btn-header-small;
    }

    @include mq(small) {
        display: none;
    }
}
