.c-search {
    position: relative;
    z-index: 1;

    @include mq($until: medium) {
        display: flex;
        position: fixed;
        top: $height-header-mobile;
        left: 0;
        right: 0;
        border-bottom: 1px solid $border-color-default;
        z-index: 50;
        background-color: $color-white;

        &--active {
            z-index: 102;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                cursor: pointer;
                z-index: 103;
            }
        }
    }

    &__container {
        @include mq($until: medium) {
            flex: 1;
        }
    }

    &__input {
        @include input;
        padding-left: 40px;
        padding-right: 40px;
        border: 0;
        border-bottom: 1px solid $border-color-default;
        background: #fff url($path-images + "/icon-search.png") no-repeat 10px
            center;

        &:focus {
            border-bottom: 1px solid $color-green;
        }

        @include mq($until: medium) {
            display: none;

            .c-search--active & {
                display: block;
                height: 50px;
                padding-right: 90px;
                padding-left: 10px;
                background: none;
            }
        }
    }

    &__close {
        position: absolute;
        display: block;
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: 0;
        width: 33px;
        z-index: 1;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: 0;
            width: 12px;
            height: 1px;
            background: $color-blue-dark;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        @include mq($until: medium) {
            right: 50px;
        }
    }

    &__trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
            fill: $color-grey;

            * {
                fill: $color-grey;
            }
        }

        .c-search--active & {
            position: absolute;
            top: 0;
            right: 0;

            svg {
                fill: $default-text-color;

                * {
                    fill: $default-text-color;
                }
            }
        }

        @include mq(medium) {
            display: none;
        }
    }
}
