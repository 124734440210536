/*------------------------------------*\
  #MAIN
\*------------------------------------*/

.c-main {
    padding-right: 0;

    @include mq(medium) {
        padding-right: $padding-right-main;
    }
}
