.o-ts-row {
    display: flex;
    flex-wrap: wrap;

    &--header {
        display: flex;
        height: $height-header;
    }

    &--footer {
        display: flex;
        line-height: 50px;
        border-top: 1px solid $border-color-default;
        box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.3);
    }

    &__cell {
        &--name {
            position: relative;
            flex: 1 0 0;
        }

        &--day {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-basis: 25%;

            &:nth-of-type(n + 2) {
                display: none;

                @include mq(small) {
                    display: flex;
                }
            }

            @include mq(small) {
                flex-basis: 10%;
            }
        }

        &--weekend {
            flex-basis: 6%;
        }

        &--summary {
            display: none;
            min-width: 8%;
            flex-basis: 8%;
            position: relative;
            vertical-align: middle;

            @include mq(small) {
                display: block;
            }
        }
    }
}
