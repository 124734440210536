.c-calendar-configurator {
    display: block;
    width: 100%;
    max-width: $width-layout;
    border-top: 1px solid $border-color-default;
    margin: $global-spacing-unit * 2 auto;
    padding: $global-spacing-unit * 2;

    &__filters {
        padding-top: 0;
        margin: 0;
    }

    &__filters-link {
        font-size: 14px;
        cursor: pointer;
        color: $color-grey;
        transition: $transition-default;

        &:hover,
        &.active {
            color: $color-blue-dark;
        }

        & + &:before {
            content: "|";
            color: $color-grey;
            padding: 0 5px;
        }
    }

    &__day-off-types {
        display: flex;
        align-items: stretch;
        justify-content: center;
        min-height: 30px;
    }

    &__day-off-type {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid $border-color-default;
        padding: 0 10px 0 8px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transition: $transition-default;

        &:before {
            content: "";
            display: block;
            width: 14px;
            height: 14px;
            border: 2px solid $color-white;
            border-radius: 50%;
            background-color: transparent;
            margin-right: 5px;
            transition: $transition-default;
        }

        &--holiday {
            border-radius: 15px 0 0 15px;

            &:before {
                box-shadow: 0 0 0 1px $color-blue;
            }

            &.c-calendar-configurator__day-off-type--active:before {
                background-color: $color-blue;
            }
        }

        &--sick-day {
            border-left: none;
            border-radius: 0 15px 15px 0;

            &:before {
                box-shadow: 0 0 0 1px $color-yellow;
            }

            &.c-calendar-configurator__day-off-type--active:before {
                background-color: $color-yellow;
            }
        }
    }

    &__day-off-type-show {
        display: block;

        .c-calendar-configurator__day-off-type--active & {
            display: none;
        }
    }

    &__day-off-type-hide {
        display: none;

        .c-calendar-configurator__day-off-type--active & {
            display: block;
        }
    }

    &__users {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: $global-spacing-unit;
    }
}
