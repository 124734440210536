.c-datepicker-popdown-button {
    display: flex;
    align-items: center;
    width: 100%;

    @include mq(1px, medium) {
        @include input;
        color: $color-grey;
        border-radius: 0;
        padding-left: $global-spacing-unit;

        &:hover {
            color: $color-blue-dark;
        }
    }

    &__activator {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 30px 0 0;

        @include mq(medium) {
            padding: 0 5px 0 $global-spacing-unit;
        }
    }

    &__activator-icon {
        @include mq(1px, medium) {
            position: absolute;
            top: 10px;
            right: 13px;
            line-height: 1.5;
        }
    }
}
