.c-selector {
    $c: &;

    &__toggle {
        display: flex;
        align-items: stretch;
        padding: 4px;
        font-size: 14px;
        color: $default-text-color;
        background-color: $backdrop-container-color;
        border-radius: $btn-border-radius-oval;

        #{$c}--inline & {
            display: inline-flex;
        }

        #{$c}--small & {
            font-size: 9px;
            padding: 2px;
        }
    }

    &__toggle-item {
        flex: 1;
        min-height: 22px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;
        text-align: center;

        &--selected {
            background-color: $color-white;
            border-radius: $btn-border-radius-oval;
            box-shadow: 0 1px rgba(39, 60, 78, 0.25);
            color: $color-blue-light;
        }

        #{$c}--inline & {
            white-space: nowrap;
        }

        #{$c}--small & {
            min-height: 12px;
            padding-left: 10px;
            padding-right: 10px;
            text-transform: uppercase;
        }
    }

    &__toggle-item-icon {
        width: 24px;
        height: 24px;
        margin: 3px 3px 3px 10px;
        fill: $default-text-color;
        transition: $transition-default;

        #{$c}__toggle-item--selected & {
            fill: $color-blue-light;
        }
    }
}
