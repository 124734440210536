.c-report-filter {
    display: block;
    background-color: $header-color-default;
    margin: ($global-spacing-unit * -2) ($global-spacing-unit * -2) 0;
    padding-top: $global-spacing-unit;

    @include mq(medium) {
        padding-top: 0;
        margin: 0;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $global-spacing-unit;

        @include mq(medium) {
            position: relative;
            flex-wrap: nowrap;
            z-index: 92;
            margin: 0;
            width: 100%;
            height: 60px;
            border: 1px solid $border-color-input;
            color: $color-grey;
            font-size: 16px;
            background: #fff;
            border-radius: 3px;
            padding: 0;
        }
    }

    &__form-input {
        position: relative;
        margin-bottom: 10px;
        flex-shrink: 0;

        &:last-child {
            border-right-width: 0;
        }

        @include mq(medium) {
            margin-bottom: 0;
        }

        &--tasks {
            width: 100%;

            @include mq(medium) {
                flex: 2;
            }

            @include mq(large) {
                flex: 3;
            }
        }

        &--projects {
            width: 50%;

            @include mq(medium) {
                flex: 2;
            }

            @include mq(large) {
                flex: 2;
            }

            .c-report-filter__form--finances & {
                @include mq(medium) {
                    flex: 1;
                    width: 100%;
                }
            }
        }

        &--projects-max {
            @include mq(medium) {
                width: auto;
                flex: 1;
            }
        }

        &--users {
            width: 50%;

            @include mq(medium) {
                flex: 2;
            }

            @include mq(large) {
                flex: 2;
            }

            .c-report-filter__form--finances & {
                @include mq(medium) {
                    flex: 1;
                    width: 100%;
                }
            }
        }

        &--billable {
            width: 50%;

            @include mq(medium) {
                flex: 1;
            }

            @include mq(large) {
                flex: 1;
            }
        }

        &--calendars {
            display: flex;
            width: 100%;

            @include mq(medium) {
                width: 26%;
            }

            @include mq(large) {
                flex: 2;
            }

            .c-report-filter__form--finances & {
                @include mq(medium) {
                    flex: 1;
                }
            }
        }

        @include mq(medium) {
            border-right: 1px solid $border-color-input;
            padding: 0;
        }
    }
}
