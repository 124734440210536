.c-teams-menu {
    @include toogle;

    font-size: $font-size-small;

    .c-holidays & {
        @include mq($until: medium) {
            display: none;
        }
    }

    &__wrapper-sterch {
        display: flex;
        align-items: stretch;
        height: calc(100vh - #{$height-header} - #{$global-spacing-unit * 2});
        z-index: 99;

        @include mq("medium") {
            position: sticky;
            top: 10px;
        }

        &--reset-padding-bottom {
            padding-bottom: 0;
        }

        @include mq($until: "medium") {
            position: fixed;
            left: -260px;
            top: 60px;
            z-index: 100;
            padding-bottom: 0;

            &--open-on-mobile {
                transform: translateX(260px);
                transition: $transition-default;
            }
        }

        .c-holidays & {
            height: auto;
        }
    }

    &__wrapper {
        width: 260px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;

        &:before {
            @include mq($until: "medium") {
                content: "";
                width: 260px;
                height: 1px;
                background-color: $color-grey-v6;
                position: absolute;
                left: 0;
                top: 48px;
            }
        }

        @include mq($until: "medium") {
            top: $height-header-mobile;
            padding-top: 70px;
            padding-left: 0;
            padding-bottom: 14px;
            padding-right: 0;
            background-color: $color-blue-lighter;
            transition: $transition-default;
        }
    }

    &__trigger-on-mobile {
        display: none;

        @include mq($until: "medium") {
            z-index: 101;
            position: fixed;
            display: flex;
            top: $height-header-mobile;
            left: 16px;
            align-items: center;
            height: 48px;
            cursor: pointer;

            svg {
                width: 15px;
                height: 14px;
            }
        }
    }

    &__wrapper-to-scroll {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-pagination;
            outline: 0;
            border-radius: $btn-border-radius-oval;
        }

        @include mq($until: "medium") {
            background-size: 100%;
            background-image: linear-gradient(
                    to bottom,
                    $color-blue-lighter,
                    rgba(255, 255, 255, 0)
                ),
                linear-gradient(
                    to top,
                    $color-blue-lighter,
                    rgba(255, 255, 255, 0)
                ),
                linear-gradient(
                    to bottom,
                    $color-grey-v5,
                    rgba(255, 255, 255, 0)
                ),
                linear-gradient(to top, $color-grey-v5, rgba(255, 255, 255, 0));
            background-position: 0 0, 0 100%, 0 0, 0 100%;
            background-repeat: no-repeat;
            background-size: 100% 30px, 100% 30px, 100% 6px, 100% 6px;
            background-attachment: local, local, scroll, scroll;
        }
    }

    &__header-list {
        margin-bottom: 8px;
        position: relative;
        cursor: pointer;

        &--active {
            font-family: $font-bold;
            text-overflow: ellipsis;
            position: relative;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 4px;
                display: block;
                width: 4px;
                height: 23px;
                background-color: $color-green;
            }
        }

        &:hover {
            background-color: $color-grey-v4;
            transition: $transition-default;

            @include mq($until: "medium") {
                background-color: inherit;
            }

            .c-teams-menu__remove-team {
                opacity: 1;
                transition: $transition-default;

                .c-calendar-configurator & {
                    display: none;
                }
            }
        }

        &--desktop {
            @include mq($until: "medium") {
                display: none;
            }
        }

        &--mobile {
            @include mq("medium") {
                display: none;
            }
        }
    }

    &__team {
        margin-left: 8px;
        position: relative;
        cursor: pointer;
        transition: $transition-default;

        &:hover {
            background-color: $color-grey-v4;
            transition: $transition-default;

            @include mq($until: "medium") {
                background-color: inherit;
            }

            .c-teams-menu__remove-team {
                opacity: 1;
                transition: $transition-default;

                .c-calendar-configurator & {
                    display: none;
                }
            }
        }

        &--drag {
            background-color: #e8ebed;
            transition: all 300ms ease;
        }

        &--selected {
            position: relative;
            font-family: $font-bold;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                left: -7px;
                top: 5px;
                display: block;
                width: 4px;
                height: 23px;
                background-color: $color-green;
            }
        }
    }

    &__team-text {
        white-space: nowrap;
        padding: 8px 26px 8px 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    &__remove-team {
        position: absolute;
        right: 8px;
        top: 10px;
        opacity: 0;

        @include mq($until: "medium") {
            display: none;
        }

        svg {
            width: 14px;
            height: 14px;
        }
    }

    &__create-new-team {
        color: $color-green;
        margin-top: 12px;
        margin-left: 16px;
        margin-bottom: 21px;
        cursor: pointer;

        @include mq($until: "medium") {
            display: none;
        }
    }

    &__create-new-item-input {
        height: 43px;
        width: 230px;
        border-bottom: 1px solid $color-green;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        outline: 0;
        padding: 12px;
        margin-left: 4px;
        border-radius: 1px;
        margin-bottom: 6px;

        @include placeholder($color-placeholder);
    }

    &__wrapper-options {
        margin-top: 12px;
        flex-shrink: 0;
        height: 117px;

        @include mq($until: "medium") {
            margin-left: 12px;
        }
    }

    &__teams {
        margin-left: 12px;
    }

    &__options-item {
        position: relative;
        cursor: pointer;
        transition: $transition-default;

        &--active {
            font-family: $font-bold;
            text-overflow: ellipsis;
            position: relative;
            cursor: pointer;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 4px;
                display: block;
                width: 4px;
                height: 23px;
                background-color: $color-green;
            }
        }

        &:hover {
            background-color: $color-grey-v4;
            transition: $transition-default;

            @include mq($until: "medium") {
                background-color: inherit;
            }

            .c-teams-menu__remove-team {
                opacity: 1;
                transition: $transition-default;
            }
        }
    }

    &__drag-over {
        background-color: $color-grey-v4;
        transition: $transition-default;
    }

    &__drag-end {
        background-color: $color-white;
        transition: $transition-default;
    }
}
