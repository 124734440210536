.c-timesheet {
    @include mq(small) {
        display: block;
        position: relative;
    }

    &--fixed-footer {
        padding-bottom: 108px;
    }
}
