.c-ts-activity {
    position: relative;
    text-align: center;
    border-left: 1px solid $border-color-default;

    &:nth-of-type(n + 3) {
        display: none;

        @include mq(small) {
            display: flex;
        }
    }

    &:hover {
        font-family: $font-bold;
        color: $color-green;
        cursor: pointer;
        border-left: 1px solid $border-color-default;
        border-right: 1px solid $border-color-default;
    }

    &--holidays {
        background-color: rgba($color-summary, 0.1);
    }

    &--sealed {
        pointer-events: none;
        position: relative;
        color: $color-grey;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -1px;
            right: 0;
            bottom: -1px;
            background: fixed
                repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 3px,
                    rgba($color-blue-darker, 0.2) 4px,
                    transparent 5px
                );
            transform-origin: left;
            transition: $transition-default;
            animation-name: sealShow;
            animation-duration: 0.3s;
        }
    }

    &--holidays-today {
        box-shadow: inset 0 0 0 1000px rgba(135, 135, 135, 0.1);
    }

    &--today {
        background-color: $color-today;
    }

    &--disabled:hover {
        cursor: not-allowed;
    }

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &--timer {
            background-color: rgba($color-green, 0.15);
            animation: pulse 1s infinite;
            font-family: $font-bold;
        }
    }

    &__timer-controls {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        background-color: rgb(208, 237, 225);
        z-index: 10;

        .c-ts-activity:hover &,
        &--focus {
            display: flex;
        }
    }

    @include mq(small) {
        border-left: 1px solid transparent;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba($color-green, 0.4);
    }
    70% {
        box-shadow: 0 0 0 5px rgba($color-green, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($color-green, 0);
    }
}
