@mixin navbar-item($class: "__link") {
    &#{$class} {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        cursor: pointer;
        position: relative;
        height: 60px;
        text-align: center;
        background-color: $color-blue-dark;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 0;
            background-color: $color-green;
            transition: all 300ms ease;
        }

        &:hover,
        &--active {
            background-color: $color-blue-darker;

            &:before {
                left: -6px;
                width: 6px;
            }

            svg * {
                fill: $color-green;
            }
        }

        svg {
            fill: $color-blue-light;
            width: 24px;
            height: 24px;

            * {
                transition: $transition-default;
            }
        }

        @include mq(1px, medium) {
            height: 65px;
            justify-content: flex-start;
            padding: 0 $global-spacing-unit;
        }
    }

    &#{$class}-label {
        padding-left: $global-spacing-unit;
        color: #fff;

        @include mq(medium) {
            display: none;
        }
    }
}
