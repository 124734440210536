.c-holidays {
    position: relative;
    display: block;

    &__add-btn {
        @include mq(1px, medium) {
            @include add-entity-mobile();
        }

        @include mq(medium) {
            @include add-entity;
            position: absolute;
            top: 122px;
            left: $global-spacing-unit * 2;
            margin-left: 0;
        }
    }

    &__add-btn-image {
        @include mq(1px, medium) {
            display: none;
        }

        @include mq(medium) {
            @include add-entity-image;

            &:after {
                top: 50%;
                margin: -25px 0 0 -25px;
            }
        }
    }

    &__add-btn-image-icon {
        @include add-entity-image-icon;
    }

    &__add-btn-text {
        @include mq(1px, medium) {
            display: none;
        }

        @include mq(medium) {
            @include add-entity-text;
            position: absolute;
            top: 20%;
            left: 90px;
            white-space: nowrap;
        }
    }

    &__tooltip-username {
        color: #fff;
        text-align: left;
    }

    &__tooltip-btn {
        cursor: pointer;
        display: block;
        padding: 8px 1px 8px 21px;
        color: #fff;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        opacity: 0.5;
        transition: $transition-default;

        &:hover {
            color: #fff;
            opacity: 1;
        }

        &--add {
            background: url($path-images + "/icon-add-event-calendar-tooltip.png")
                no-repeat left center;
        }

        &--delete {
            background: url($path-images + "/icon-close-event-calendar-tooltip.png")
                no-repeat left center;
        }

        &--edit {
            background: url($path-images + "/icon-edit-event-calendar-tooltip.png")
                no-repeat left center;
        }
    }
}
