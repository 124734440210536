.c-user-avatar {
    &__photo {
        margin: ($global-spacing-unit * -2) auto $global-spacing-unit;
        height: 114px;
        width: 114px;
        box-shadow: 0 2px 2px 0 rgba($color-blue-dark, 0.2);
        border-radius: 100%;
    }

    &__photo-img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
}
