.c-configurator-user-entry {
    @include user-entry();

    width: 100%;
    opacity: 0.5;
    padding-right: $global-spacing-unit/2;
    transition: $transition-default;

    @include mq(tiny, medium) {
        width: 50%;
    }

    @include mq(medium) {
        width: calc(100% / 3);

        .o-layout__item--full-width & {
            width: calc(100% / 4);
        }
    }

    &--visible {
        opacity: 1;
    }

    &--current {
        color: $color-green;
        font-weight: bold;
    }

    &__photo {
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: 33;
            width: 100%;
            height: 100%;
            text-align: center;
            border-radius: 100%;
            background-image: url($path-images + "/icon-check-holidays-user-photo.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-color: $color-blue;
            opacity: 0;
            transition: $transition-default;
        }

        .c-configurator-user-entry--current &:after {
            background-color: $color-green;
        }

        .c-configurator-user-entry--visible &:after {
            opacity: 1;
        }
    }

    &__name {
        min-width: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__badge {
        flex-shrink: 0;
        display: block;
        margin: 0 $global-spacing-unit 0 $global-spacing-unit/2;
        padding: 0 7px;
        line-height: 24px;
        border-radius: 50%;
        font-weight: normal;
        background: $user-link-bg-color;
    }
}
