$default-text-color: $color-blue-dark;

html {
    min-height: 100%;
    position: relative;
}

body {
    min-height: 100%;
    overflow: hidden;
    color: $default-text-color;
}
