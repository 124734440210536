.c-timer-stop {
    cursor: default;
    white-space: nowrap;
    padding: 0 10px;

    &__btn {
        display: inline-block;
        border: none;
        width: 25px;
        height: 100%;
        padding: 0 5px;
        cursor: pointer;

        &--cancel {
            display: none;
            background: transparent url($path-images + "/icons/x.svg") center
                no-repeat;
            background-size: 12px;

            .c-timer-stop:hover &,
            .c-timer-stop--focus & {
                display: inline-block;
            }
        }

        &--stop {
            background: transparent url($path-images + "/icons/stop.svg") center
                no-repeat;
            background-size: 15px;
        }
    }

    &.c-timer-stop--focus {
        display: flex;
    }
}
