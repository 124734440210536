.c-pagination {
    margin: 2 * $global-spacing-unit 0;
    color: $color-pagination;
    font-size: $font-size-small;
    display: flex;

    @include mq($until: medium) {
        margin-left: $global-spacing-unit;
    }

    &__wrapper-button {
        margin-left: 12px;
        display: flex;
    }

    &__button {
        background-color: $color-white;
        border: 1px solid $color-pagination;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        line-height: 14px;
        padding: 0 7px;
        cursor: pointer;
        transition: $transition-default;
        outline: 0;
        color: inherit;

        &:hover {
            background-color: $backdrop-container-color;
            transition: $transition-default;
        }

        &:disabled,
        &--disabled {
            color: $color-pagination;
            cursor: not-allowed;
            pointer-events: none;

            svg {
                fill: $color-pagination;
            }

            &:hover {
                background-color: inherit;
            }
        }

        svg {
            width: 5px;
            height: 8px;
            margin-left: 4px;
            margin-right: 4px;
        }

        &--prev {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;

            svg {
                transform: rotate(180deg);
            }
        }

        &--next {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            margin-left: -1px;
        }
    }
}
