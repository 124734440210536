.c-day-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: calc((100% - (2 * #{$width-btn-header-tiny})));
    min-width: calc((100% - (2 * #{$width-btn-header-tiny})));
    vertical-align: middle;
    text-align: center;
    color: $color-summary;

    &--today {
        color: $color-blue-darker;
        font-weight: bold;
    }

    &--sealed {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: -1px;
            background: fixed
                repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 3px,
                    rgba($color-blue-darker, 0.2) 4px,
                    transparent 5px
                );
            transform-origin: left;
            transition: $transition-default;
            animation-name: sealShow;
            animation-duration: 0.3s;
        }
    }

    &--holiday,
    &--sick-day {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 50%;
            max-width: 50px;
            margin: 0 auto;
            opacity: 0.15;
            z-index: 0;
        }
    }

    &--holiday {
        &:before {
            background: url($path-images + "/icon-plane.svg") center no-repeat;
            background-size: 100%;
        }
    }

    &--sick-day {
        &:before {
            background: url($path-images + "/icon-ambulance.svg") center
                no-repeat;
            background-size: 100%;
        }
    }

    &__date {
        line-height: 20px;
        font-size: 20px;
    }

    &__date-month {
        @include mq(small) {
            .c-day-header--weekend & {
                display: none;
            }
        }
    }

    &__day {
        text-transform: uppercase;
        font-size: 12px;
    }

    @include mq(tiny) {
        flex-basis: calc((100% - (2 * #{$width-btn-header-small})));
        min-width: calc((100% - (2 * #{$width-btn-header-small})));
    }

    @include mq(small) {
        flex-basis: 10%;
        min-width: 10%;

        &--weekend {
            min-width: 6%;
            flex-basis: 6%;
        }
    }
}

@keyframes sealShow {
    from {
        transform: rotateY(-90deg);
    }
    to {
        transform: rotateY(0);
    }
}
