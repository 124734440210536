.c-hours-picker {
    display: flex;
    align-items: center;
    width: 100%;

    &__hours,
    &__minutes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 2;
        font-size: 12px;
        text-align: center;
    }

    &__divide {
        flex: 1;
        font-size: 12px;
        text-align: center;
    }

    &__btn {
        position: relative;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    &__btn-icon {
        width: 17px;
        height: 9px;
        fill: $color-green;
    }
}
