.c-projects-list {
    display: block;

    &__summary {
        @include list-summary;
    }

    &__summary-quantity {
        @include list-summary-quantity;
    }
}
