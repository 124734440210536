.c-timepot {
    display: block;
    height: 100vh;
    position: relative;

    &--mobile-overflow {
        overflow: hidden;
        height: calc(100vh - #{$height-header-mobile});
    }

    &--message {
        @include mq(small) {
            padding-top: $height-message;
        }
    }

    @include mq(1px, medium) {
        padding-top: $height-header-mobile;
    }
}
