.c-settings {
    $c: &;

    &__container {
        width: 100%;
        max-width: 690px;
        padding: $global-spacing-unit;
        margin: 0 auto;
    }

    &__section {
        border-bottom: 1px solid $color-pagination;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__section-header {
        font-size: 16px;
        color: $color-pagination;
        margin-top: $global-spacing-unit * 2;
    }

    &__position,
    &__subposition {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 24px 0;
        border-bottom: 1px solid $border-color-default;

        &:last-of-type {
            border-bottom: none;
        }

        @include mq($until: small) {
            flex-direction: column;
        }
    }

    &__subposition {
        padding-top: 0;
        align-items: flex-start;
    }

    &__position-label {
        width: 33%;
        flex-shrink: 0;
        font-size: 14px;

        @include mq($until: small) {
            padding-bottom: $global-spacing-unit/2;
        }
    }

    &__position-action {
        flex: 1;

        &--edit {
            position: relative;
            font-size: 16px;
            transition: $transition-default;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                top: $global-spacing-unit * -0.5;
                left: $global-spacing-unit * -0.5;
                right: 0;
                bottom: $global-spacing-unit * -0.5;
                background-color: #f9fafb;
                opacity: 0;
                transition: $transition-default;
                z-index: 1;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__position-action-desc {
        position: relative;
        z-index: 2;
    }

    &__position-action-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        fill: $color-pagination;
        top: 50%;
        right: $global-spacing-unit;
        margin-top: -8px;
        opacity: 0;
        transition: $transition-default;
        z-index: 2;

        #{$c}__position-action--edit:hover & {
            opacity: 1;
        }
    }

    &__position-label,
    &__position-action {
        @include mq($until: small) {
            flex: 1;
            width: 100%;
        }
    }

    &__position-help[data-tooltip] {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: $color-grey-v6;
        top: 50%;
        left: -20px;
        margin-top: -6px;
        border-radius: 50%;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
        }
    }
}
