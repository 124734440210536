/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/

.o-layout {
    margin: 0;
    padding: 0;
    padding-top: 50px;
    list-style: none;
    font-size: 0;

    &__item {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 260px);
        font-size: $font-size-base;

        // margin-left: 280px;

        &:first-of-type {
            padding-left: 0;
        }

        @include mq($until: medium) {
            width: 100%;
            padding-left: 0;
        }

        @include mq(medium) {
            padding-left: $global-spacing-unit;
        }

        &--teams-menu {
            width: auto;

            @include mq($until: medium) {
                width: 0;
            }
        }

        &--full-width {
            width: 100%;
            padding-left: 0;
        }
    }

    @include mq(medium) {
        margin: 0 $global-spacing-unit;
        padding-top: $global-spacing-unit * 2;
    }

    @include mq(large) {
        margin: 0 100px 0 $global-spacing-unit * 2;
    }

    &__stretch {
        display: flex;
        align-content: stretch;
    }
}
