.o-project-entry {
    &__entry {
        display: flex;
        align-items: stretch;
        margin: 0 0 6px;
        width: 100%;
        min-height: 42px;
    }

    &__entry-column {
        display: flex;
        align-items: center;
    }
}
