.c-double-calendar-datepicker {
    display: flex;

    &__from,
    &__to {
        display: flex;
        width: 50%;
        cursor: pointer;
        position: relative;
    }

    &__from {
        @include mq(1px, medium) {
            .opensleft {
                right: auto;
                left: 0;

                &:after {
                    left: 20px;
                    right: auto;
                }

                &:before {
                    left: 18px;
                    right: auto;
                }
            }
        }
    }
}
