.o-list-row {
    $c: &;

    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid #e8ebed;

    &:hover {
        background-color: $color-grey-light;
    }

    &__cell {
        display: flex;
        align-items: center;

        &--name {
            margin: 0 $global-spacing-unit;
            text-align: left;
            overflow: hidden;

            > * {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            @include mq($until: large) {
                flex: 1;
            }
        }

        &--hover {
            cursor: pointer;

            &:hover {
                color: $color-green;
            }
        }

        &--info {
            font-size: 12px;
            padding-right: $global-spacing-unit;
            justify-content: flex-end;
            white-space: nowrap;
            color: $color-blue-light;
        }

        &--links {
            @include mq($until: large) {
                display: none;
                position: absolute;
                background: #fff;
                min-width: 180px;
                top: 100%;
                right: 0;
                padding: 0;
                z-index: 2;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                    0 1px 2px rgba(0, 0, 0, 0.24);

                &:after {
                    content: "";
                    position: absolute;
                    top: -5px;
                    right: 15px;
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    border-left: 1px solid $border-color-default;
                    border-top: 1px solid $border-color-default;
                    transform: rotate(45deg);
                }

                .o-list-row--dropdown & {
                    display: block;
                }
            }

            @include mq(large) {
                flex: 1;
                flex-direction: row;
            }
        }

        &--teams {
            display: none;
            padding: 0 $padding-base-horizontal 0 0;
            justify-content: flex-end;
            flex-wrap: wrap;
            max-width: 250px;
            opacity: 1;
            transition: $transition-default;
            transform-origin: right;
            transform: rotateY(-90deg);

            @include mq(large) {
                .o-list-row:hover & {
                    display: flex;
                    opacity: 1;
                    transform: rotateY(0deg);
                }
            }
        }

        &--teams-count {
            @include mq(large) {
                .o-list-row:hover & {
                    display: none;
                }
            }
        }

        &--raports {
            width: 50px;
            flex-shrink: 0;
            justify-content: center;
            background-color: #fff;
            transition: $transition-default;

            svg {
                max-width: 24px;
                max-height: 24px;
                fill: $color-blue-light;
                opacity: 0.5;
                transition: $transition-default;
            }

            &:hover {
                background-color: $color-grey-light;

                svg {
                    opacity: 1;
                }
            }

            @include mq($until: large) {
                display: none;
            }
        }

        &--dropdown-mobile {
            width: 40px;
            flex-shrink: 0;
            justify-content: center;
            cursor: pointer;

            svg {
                max-width: 16px;
                max-height: 16px;
            }

            @include mq(large) {
                display: none;
            }
        }

        &--color {
            width: 8px;
            flex-shrink: 0;
            transition: $transition-default;

            .o-list-row:hover & {
                width: 16px;
            }
        }
    }

    &__dragable {
        display: flex;
        align-items: stretch;
        min-height: 50px;
        min-width: 0;

        @include mq($until: large) {
            flex: 1;
        }
    }

    // Avatar
    // --------------------------------------------------

    &__photo {
        flex-shrink: 0;
        position: relative;
        margin: 8px 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $color-grey-light url($path-images + "/icons/user.svg")
            center no-repeat;
        background-size: 100%;
        box-shadow: 0 3px 4px 0 rgba($color-blue-darker, 0.1);
        transition: $transition-default;

        @include mq($until: medium) {
            margin-left: 10px;
        }
    }

    &__photo-image {
        object-fit: cover;
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }

    // Links block / links dropdown (mobile)
    // --------------------------------------------------

    &__link {
        cursor: pointer;
        transition: $transition-default;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include mq($until: large) {
            justify-content: flex-start;
            padding: 10px 20px;
            width: 100%;
        }

        @include mq(large) {
            display: none;
            justify-content: center;
            width: 40px;
            height: 100%;
            opacity: 0;

            .o-list-row:hover & {
                display: flex;
                opacity: 0.3;

                &:hover {
                    opacity: 1;
                    background-color: rgba($color-grey, 0.3);
                }
            }
        }
    }

    &__link-icon {
        display: flex;
        justify-content: center;

        svg {
            max-width: 16px;
            max-height: 16px;
            fill: $default-text-color;
        }

        &--nonbillable {
            margin-right: 20px;

            svg {
                fill: $color-green;
            }
        }

        @include mq($until: large) {
            margin-right: 5px;
        }
    }

    &__link-desc {
        @include mq(large) {
            display: none;
        }
    }

    // Teams
    // --------------------------------------------------

    &__teams {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    &__team,
    &__teams-add,
    &__teams-more {
        position: relative;
        display: block;
        background-color: #fff;
        border: 1px solid rgba($default-text-color, 0.2);
        border-radius: 10px;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
        color: $default-text-color;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
        padding: 0 15px 0 7px;
        margin: 2px 0 2px 4px;

        &--hidden {
            display: none;
        }
    }

    &__team,
    &__teams-more {
        @include mq(large, larger) {
            display: none;
        }
    }

    &__teams-add {
        padding: 0 7px;
        color: $color-green;
        cursor: pointer;
    }

    &__teams-more {
        padding: 0 7px;
        color: rgba($default-text-color, 0.8);
    }

    &__team-remove {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 7px;
        width: 14px;
        height: 14px;
        opacity: 0.3;
        transition: $transition-default;

        &:before,
        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -3px;
            content: "";
            width: 1px;
            height: 7px;
            background: $color-blue-dark;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    // Dropdown overlay
    // --------------------------------------------------

    &__overlay {
        @include mq($until: large) {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

            .o-list-row--dropdown & {
                display: block;
            }
        }
    }
}
