.c-ts-header {
    background-color: $header-color-default;
    position: relative;
    &__logo {
        @include mq(1px, medium) {
            position: fixed;
            display: inline-block;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: $height-header-mobile;
            width: 120px;
            padding-left: 40px;
            background: url($path-images + "/logo-sign.svg") no-repeat center
                left;
            background-size: auto $height-logo;
            z-index: 51;

            &:after {
                content: attr(title);
                line-height: 60px;
                font-family: $font-bold;
                font-size: 16px;
                color: #fff;
            }
        }

        @include mq(medium) {
            @include logo;
            display: block;
        }
    }

    &__name-wrapper,
    &__summary-wrapper {
        @include mq(1px, small) {
            flex-basis: 0;
            min-width: 0;
        }

        @include mq(small) {
            display: block;
        }
    }
}
