.c-timesheet-table {
    display: block;

    &__footer {
        background-color: #fff;

        .c-timesheet--fixed-footer & {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;

            @include mq(medium) {
                right: $width-menu;
            }
        }
    }
}
