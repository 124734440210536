.c-filter-display-field {
    display: block;
    position: relative;
    padding: 0 $global-spacing-unit 10px;
    margin-top: 10px;
    background: $filter-background;
    border: 1px solid $filter-border;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 4px 4px -2px rgba($color-grey, 0.45);
    min-height: 52px;

    @include mq(medium) {
        margin-top: 0;
    }

    &__message {
        margin: 10px 10px 0 0;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    &__filter {
        @include filter-pill;
    }

    &__filter-cancel {
        @include filter-pill-remove;
    }
}
