.c-user-menu {
    position: relative;

    &__avatar-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        @include mq(1px, medium) {
            padding: 15px;
            background-color: $color-blue-darker;

            &:after {
                content: "";
                position: absolute;
                top: 40px;
                right: 20px;
                width: 0;
                height: 0;
                border-top: 7px solid $color-blue-light;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                transition: all 200ms ease;
            }

            .c-user-menu--active & {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__avatar {
        position: relative;
        background-color: $color-blue-dark;
        background-size: cover;
        background-repeat: no-repeat;
        width: $width-menu - $global-spacing-unit * 3;
        height: $width-menu - $global-spacing-unit * 3;
        border-radius: 100%;
        flex-shrink: 0;
        z-index: 101;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

        @include mq(medium) {
            width: $width-menu;
            height: $height-header;
            border-radius: 0;
            background-color: $color-blue-darker;
            box-shadow: none;
        }
    }

    &__avatar-info {
        flex-grow: 1;
        color: #fff;
        padding-left: 15px;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include mq(medium) {
            display: none;
        }
    }

    &__avatar-info-name {
        font-size: 18px;
        font-family: $font-bold;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__avatar-info-email {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__overlay {
        @include mq(medium) {
            display: none;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: default;
            background: rgba($color-blue-darker, 0.5);
            z-index: 1;

            .c-user-menu--active & {
                display: block;
            }
        }
    }

    &__panel {
        transition: all 200ms ease;
        overflow: hidden;

        @include mq(1px, medium) {
            height: 0;
            border-bottom: 1px solid $color-blue-darker;

            .c-user-menu--active & {
                height: auto;
            }
        }

        @include mq(medium) {
            position: absolute;
            width: $width-menu * 2;
            top: 0;
            right: $width-menu;
            transform: rotateY(90deg);
            transform-origin: right;
            background: #fff;
            z-index: 99;
            box-shadow: 0;

            .c-user-menu--active & {
                transform: rotateY(0deg);
                box-shadow: 0 5px 10px 5px rgba($color-blue-darker, 0.25);
            }
        }

        @include mq(small) {
            width: auto;
            min-width: $width-menu * 2;
            max-width: $width-menu * 3;
        }
    }

    &__panel-info {
        padding: 30px 25px;
        min-height: $height-header;

        @include mq(1px, medium) {
            display: none;
        }
    }

    &__panel-info-name {
        font-size: 18px;
        font-family: $font-bold;
        line-height: 24px;
    }

    &__panel-info-email {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__panel-control {
        cursor: pointer;
        display: block;
        position: relative;
        padding: 0 30px 0 25px;
        line-height: 60px;
        background: #fff;
        border-top: 1px solid $border-color-default;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include user-select(none);

        &:before {
            content: "";
            position: absolute;
            top: 45%;
            right: 20px;
            width: 0;
            height: 0;
            border-top: 7px solid $border-color-default;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            transition: all 200ms ease;
        }

        &--active:before {
            transform: rotate(180deg);
        }

        @include mq(1px, medium) {
            padding: 0 20px;
            color: #fff;
            background: $color-blue-darker;
            border-bottom: 1px solid;
            border-color: $color-blue-dark;

            &:before {
                border-top-color: $color-blue-light;
            }

            &--active {
                border-bottom: none;
            }
        }
    }

    &__panel-nav {
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;
        background-color: $border-color-default;

        &--active {
            display: block;
        }

        &--white {
            background-color: #fff;
        }
    }

    &__panel-nav-link {
        cursor: pointer;
        display: block;
        padding: 13px 25px;
        font-size: 16px;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            font-family: $font-bold;
        }

        &--current:before {
            display: inline-block;
            content: "";
            margin: 0 5px 0 0;
            vertical-align: middle;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: $color-green;
        }

        &--signout {
            @include mq(1px, medium) {
                display: none;
            }
        }

        @include mq(1px, medium) {
            padding: 0 20px;
            line-height: 60px;
            color: #fff;
            background: $color-blue-darker;

            &:hover {
                font-family: $font-regular;
                background: $color-blue-dark;
            }
        }
    }

    &__panel-nav-input {
        display: block;
        height: 50px;
        width: 100%;
        padding: 0 25px;
        border: none;
        background-color: $border-color-default;
    }
}
