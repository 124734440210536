.c-users-sidebar {
    @include mq(1px, medium) {
        text-align: center;
    }

    &__sidebar {
        @include mq(1px, medium) {
            @include add-entity-mobile;
        }

        @include mq(medium) {
            @include add-entity;
        }
    }

    &__sidebar-image {
        @include mq(1px, medium) {
            display: none;
        }

        @include mq(medium) {
            @include add-entity-image;
        }
    }

    &__sidebar-image-icon {
        @include add-entity-image-icon;
    }

    &__sidebar-text {
        @include mq(1px, medium) {
            display: none;
        }

        @include mq(medium) {
            @include add-entity-text;
        }
    }

    &__btn-edit {
        display: none;
        margin: 0 auto;
        position: relative;
        padding: 0 0 $global-spacing-unit;
        text-align: center;
        cursor: pointer;

        @include mq(medium) {
            display: block;
            padding: $global-spacing-unit 0 0;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 35%;
                width: 30%;
                height: 1px;
                background: $color-grey-light;
            }
        }
    }
}
