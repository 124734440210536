@mixin list-summary {
    margin: 20px 0 40px;
    padding: 20px 0 0 0;
    border-top: 1px solid $color-grey-light;
    opacity: 0.5;
}

@mixin list-summary-quantity {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: $color-grey-light;
    border-radius: 100%;
}
