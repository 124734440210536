.c-project-teams-modal {
    @include pricing-modal;

    &__form {
        padding: $padding-base-vertical 4 * $padding-base-vertical 2 *
            $padding-base-vertical;
        margin-bottom: 2 * $padding-base-vertical;
    }

    &__team {
        @include filter-pill;
    }

    &__team-remove {
        @include filter-pill-remove;
    }
}
