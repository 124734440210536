.c-profile {
    @include backdrop-header;
    @include validation;

    &__form {
        margin: 0 auto;
        max-width: 310px;
        width: 100%;
    }

    &__form-first-input,
    &__form-last-input {
        @include input;
    }

    &__form-skip-now {
        margin: ($global-spacing-unit / 2) 0 $global-spacing-unit;
        line-height: 1.5;
    }

    &__form-skip-now-link {
        border-bottom: 1px solid $border-color-default;
        cursor: pointer;
    }

    &__subtitle {
        padding: ($global-spacing-unit / 2) 0 30px;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
    }

    &__upload-label {
        display: block;
    }

    &__avatar {
        position: relative;
        display: block;
        margin: 0 auto $global-spacing-unit;
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background-color: $color-grey-light;
        box-shadow: 0 4px 4px 0 rgba($color-blue-darker, 0.2);
        overflow: hidden;

        &:after {
            opacity: 0;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 100%;
            background: rgba($color-blue-dark, 0.75)
                url($path-images + "/icons/upload.svg") center no-repeat;
            background-size: 25%;
            transition: opacity 300ms ease;
        }

        .c-profile__upload-label:hover &:after {
            opacity: 1;
        }
    }

    &__avatar-img {
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__avatar-label {
        display: inline-block;
        margin: 10px auto;
        border-bottom: 1px solid $border-color-default;
    }
}
