.c-user-rate-override {
    position: relative;

    &__btn-delete {
        position: absolute;
        top: 0;
        left: 100%;
        height: $input-height-base;
        width: $input-height-base;
        cursor: pointer;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 12px;
            height: 2px;
            background-color: $color-grey;
            transform-origin: center;
            transition: $transition-default;
            top: 50%;
            left: 50%;
            margin-top: -1px;
            margin-left: -6px;
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }

        &:hover {
            &:before,
            &:after {
                background-color: $color-red;
            }
        }
    }
}
