$reports-container-bg: $header-color-default;

.c-reports {
    &__container {
        padding: $global-spacing-unit * 2;
    }

    &__content {
        .c-reports__container--empty & {
            display: none;
        }
    }

    &__bar-chart-container {
        position: relative;
        padding-top: $global-spacing-unit * 3;
    }

    &__bar-chart-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__bar-chart-header-actions {
        flex: 1;
        display: flex;
        align-items: center;
    }

    &__bar-chart-loader-container {
        height: 320px;
        position: relative;
    }

    &__bar-chart-total {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
    }

    &__bar-chart-report-download-container {
        display: none;
        align-items: center;
        color: $color-blue-dark;

        @include mq(medium) {
            display: flex;
        }

        &.u-tooltip--top:after {
            width: 200px;
            max-width: none;
        }
    }

    &__admin-report-links {
        display: inline;
    }

    &__bar-chart-report-download-link {
        cursor: pointer;
        opacity: 0.5;
        user-select: none;

        &:hover {
            opacity: 1;

            &:before {
                opacity: 0.5;
            }
        }

        & + & {
            margin-left: 4px;

            &:before {
                content: "| ";
                opacity: 0.5;
            }
        }
    }

    &__billable-selector {
        margin-left: $global-spacing-unit * 2;
    }

    &__bar-chart-visibility-trigger {
        flex: 1;
        display: none;
        cursor: pointer;
        color: $color-blue-dark;
        opacity: 0.5;
        transition: $transition-default;
        user-select: none;
        text-align: right;

        &:hover {
            opacity: 1;
        }

        @include mq(medium) {
            display: block;
        }
    }

    &__bar-chart-visibility-trigger-icon {
        &:before {
            transition: $transition-default;
            transform: rotate(180deg);
        }

        .c-reports__bar-chart-visibility-trigger--visible &:before {
            transform: rotate(0deg);
        }
    }

    &__graphs {
        position: relative;
        padding: ($global-spacing-unit * 3) 0 0;
        min-height: 350px;
        background: $reports-container-bg;
        margin: $global-spacing-unit * 3 ($global-spacing-unit * -1) 0;

        @include mq(medium) {
            margin: $global-spacing-unit * 2 0 0;
        }
    }

    &__graphs-desc {
        display: none;
        text-align: center;

        @include mq(medium) {
            display: block;
        }
    }

    &__graphs-desc-element {
        display: inline-flex;
        align-items: center;

        & + & {
            margin-left: $global-spacing-unit;
        }
    }

    &__graphs-desc-bar {
        display: inline-block;
        width: 15px;
        height: 3px;
        margin-right: 5px;

        &--income {
            background-color: $color-green;
        }

        &--expense {
            background-color: $color-red;
        }
    }

    &__graphs-tabs {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    &__graphs-tabs-option {
        width: 100%;
        text-align: center;

        @include mq(medium) {
            width: 20%;
        }
    }

    &__graphs-tabs-option-link {
        position: relative;
        display: inline-block;
        padding: 0 3px 2px;
        color: $color-grey;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 4px solid transparent;
        cursor: pointer;

        &:hover,
        &.active {
            color: $color-blue-dark;
            border-color: $color-green;
        }

        &:after {
            display: none;
            content: "";
            position: absolute;
            right: 0;
            bottom: -48px;
            width: 1px;
            height: 48px;
            background: $color-green;
        }

        &--default {
            cursor: default;
        }
    }

    &__graphs-type-picker {
        position: relative;
        text-align: right;
        height: 24px;
        width: 100%;
        margin: 0 auto $global-spacing-unit;

        @include mq(medium) {
            position: absolute;
            top: 62px;
            right: 27px;
            width: 130px;
            margin: 0;
        }
    }

    &__graphs-type-picker-link {
        display: inline-block;
        cursor: pointer;
        width: 34px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.5;
        border-radius: 3px;
        transition: $transition-default;

        &:hover,
        &--active {
            opacity: 1;
            background-color: rgba($color-grey, 0.2);
        }

        &--donut {
            background-image: url($path-images + "/icon-graph-type-pie.png");
        }

        &--bar {
            background-image: url($path-images + "/icon-graph-type-line.png");
        }

        &--users {
            background-image: url($path-images + "/icon-graph-type-user-breakdown.svg");
        }

        &--day-details {
            background-image: url($path-images + "/icon-day-details-toggle.png");
        }
    }

    &__alert {
        display: none;
        margin: $global-spacing-unit ($global-spacing-unit * -1) 0;

        @include alert("info");

        @include mq(medium) {
            margin: $global-spacing-unit 0 0;
        }

        .c-reports__container--empty & {
            display: block;
        }
    }

    &__alert-link {
        color: $color-green;
    }

    &__public-desc {
        margin-bottom: $global-spacing-unit * 2;
    }
}
