.o-mockup {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;

    &__header,
    &__content {
        width: 100%;
        flex-grow: 1;

        @include mq(medium) {
            width: calc(100% - #{$width-menu});
        }
    }

    &__header-avatar,
    &__navbar {
        display: none;
        width: $width-menu;
        flex-shrink: 0;

        @include mq(medium) {
            display: block;
        }
    }

    &__header {
        height: $height-header-mobile;
        background: $color-blue-dark;

        @include mq(medium) {
            height: $height-header;
            background: $header-color-default url($path-images + "/logo.svg")
                no-repeat 56px center;
            background-size: auto $height-logo;
        }
    }

    &__header-avatar {
        height: $height-header;
        background: $color-blue-darker;
    }

    &__content {
        position: relative;
        height: 100vh;
    }

    &__content-loader {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;

        @include loader(30px);
    }

    &__navbar {
        background: $color-blue-dark;
    }
}

.o-mockup-auth {
    height: 100vh;
    background: $backdrop-color;

    &:before {
        content: "";
        display: block;
        height: $height-header-mobile;
        width: 100%;
        background: url($path-images + "/logo-white.svg") no-repeat center;
        background-size: auto $height-logo;

        @include mq(small) {
            height: 150px;
            margin-bottom: 4px;
        }
    }

    &__content {
        display: block;
        position: relative;
        background: #fff;
        margin: 0 $global-spacing-unit;
        width: 100%;
        max-width: 650px;
        height: 504px;
        border-radius: 2px;
        text-align: center;

        @include mq(small) {
            margin: 0 auto;
        }
    }
}
