.c-user-day-activity-details {
    display: block;
    position: relative;

    & + &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: $global-spacing-unit * 4;
        height: 1px;
        background: $border-color-default;
    }
}
