.c-ts-footer {
    &__btn-container {
        position: static;

        @include mq(1px, small) {
            display: flex;
            flex: 1 0 0;
        }
    }

    &__btn-add-new-project {
        @include mq(1px, medium) {
            @include add-entity-mobile;
        }

        @include mq(medium) {
            position: absolute;
            bottom: 25px;
            left: calc(30% - 25px);
            background-color: $color-blue-dark;
            box-shadow: 0 2px 5px 1px rgba(45, 45, 53, 0.35);
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50px;
            text-align: center;
            cursor: pointer;
            z-index: 5;

            &:before,
            &:after {
                content: "";
                top: 50%;
                left: 50%;
                margin-left: -10px;
                position: absolute;
                width: 20px;
                height: 2px;
                background-color: #fff;
                opacity: 0.5;
                transition: $transition-default;
            }

            &:before {
                transform: rotate(90deg);
            }

            &:hover:before,
            &:hover:after {
                opacity: 1;
            }

            &--fixed {
                position: fixed;
                left: calc(30% - 25px);

                @include mq(medium) {
                    left: calc(((100% - #{$width-menu}) * 0.3) - 25px);
                }
            }
        }
    }

    &__summary {
        text-align: center;
        box-sizing: border-box;
        font-size: 14px;
        color: $color-summary;
        font-weight: bold;
    }
}
