.c-calendar {
    width: 100%;
    max-width: $width-layout;
    margin: 0 auto;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 $global-spacing-unit * 2 $global-spacing-unit;
    }

    &__header-title {
        flex: 2;
        font-size: 24px;
        text-align: center;
    }

    &__header-mode-switch {
        flex: 1;
    }

    &__header-nav {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
