.c-date-picker-range {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;

    &--report {
        @include mq($until: medium) {
            width: 100%;
        }
    }

    &__group {
        flex: 1;
        position: relative;
        z-index: 12;

        &--start {
            margin-right: $global-spacing-unit/2;

            .c-date-picker-range--report & {
                margin-right: 0;
            }
        }

        &--end {
            margin-left: $global-spacing-unit/2;

            .c-date-picker-range--report & {
                margin-left: 0;
            }
        }
    }

    &__group-label {
        position: absolute;
        top: 8px;
        left: 16px;
        font-size: 10px;
        color: $color-grey-v5;

        .c-date-picker-range--report & {
            @include mq(medium) {
                top: 14px;
            }
        }
    }

    &__group-input {
        @include input;
        padding: 17px 15px 3px;
        cursor: pointer;

        &[readonly] {
            background: transparent;

            .c-date-picker-range--report & {
                background: $color-white;
            }
        }

        .c-date-picker-range--report & {
            @include mq(medium) {
                border: none;
                height: 100%;
            }
        }
    }

    &__flyout {
        position: absolute;
        top: 100%;
        top: calc(100% + 4px);
        right: 0;
        min-width: 100%;
        padding: $global-spacing-unit;
        background: $color-white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        z-index: 12;

        &--align-left {
            .c-date-picker-range--report & {
                @include mq($until: medium) {
                    right: auto;
                    left: 0;
                    width: 50%;
                    min-width: 380px;
                }
            }
        }

        &--align-right {
            .c-date-picker-range--report & {
                @include mq($until: medium) {
                    width: 50%;
                    min-width: 380px;
                }
            }
        }
    }

    &__flyout-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: 10;
    }

    &__predefined-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        background-color: $header-color-default;
        padding: 0 $global-spacing-unit;
        margin: $global-spacing-unit -1 * $global-spacing-unit -1 *
            $global-spacing-unit;
    }

    &__predefined-action {
        width: 25%;
        padding: $global-spacing-unit/2 0;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
    }
}
