.c-ts-week-picker {
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 90;
        height: $height-header;

        svg {
            width: 24px;
            height: 24px;
            fill: $color-grey;
            transition: $transition-default;
        }

        &:hover svg {
            fill: $color-blue-dark;
        }
    }

    &__flyout {
        position: absolute;
        top: calc(100% + 1px);
        right: 10px;
        background: $color-white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        padding: $global-spacing-unit/2;
        z-index: 2;
    }
}
