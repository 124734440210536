.c-year {
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 2 * $global-spacing-unit;

        &:before,
        &:after {
            content: "";
            width: 100%;
            order: 1;
        }

        @include mq($until: 1360px) {
            justify-content: space-around;
        }
    }
}
