.c-generic-modal {
    &__modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        outline: 0;
        background-color: rgba($color-blue-dark, 0.95);
    }

    &__modal-header {
        padding: $global-spacing-unit 0 ($global-spacing-unit * 2);

        @include mq(small) {
            padding: $global-spacing-unit $global-spacing-unit
                ($global-spacing-unit * 2);
        }
    }

    &__modal-header-title {
        margin: 0;
        line-height: 1.4;
        font-size: 24px;
        font-weight: normal;
    }

    &__modal-header-subtitle {
        margin: 0;
    }

    &__modal-body {
        position: relative;
        padding: 0 0 ($global-spacing-unit * 2);

        @include mq(small) {
            padding: 0 $global-spacing-unit ($global-spacing-unit * 2);
        }
    }

    &__modal-footer {
        padding: 0 0 $global-spacing-unit;

        @include mq(small) {
            padding: 0 $global-spacing-unit $global-spacing-unit;
        }

        &--loader {
            min-height: 69px;
        }
    }

    &__dialog {
        position: relative;
        width: auto;
        margin: $global-spacing-unit;
        z-index: 1052;
        border-radius: $border-radius-base;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);

        @include mq(small) {
            width: 650px;
            margin: 150px auto;

            &--small {
                width: 300px;
            }
        }

        @include mq(medium) {
            &--large {
                width: 900px;
            }
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        right: 20px;
        bottom: 0;
        left: 0;
        z-index: 1051;
    }
}
