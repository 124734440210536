@mixin backdrop($container-background: #fff) {
    position: fixed;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    overflow: auto;
    background-color: $backdrop-color;
    padding: 0 $global-spacing-unit;

    &__header {
        display: block;
        text-align: center;
    }

    &__header-logo {
        display: inline-block;
        height: $height-header-mobile;
        width: 150px;
        background: url($path-images + "/logo-white.svg") no-repeat center;
        background-size: auto $height-logo;

        @include mq(small) {
            height: 150px;
        }
    }
}

@mixin backdrop-header() {
    &__header {
        margin: $global-spacing-unit 0 35px 0;
    }

    &__header-title {
        line-height: 1.4;
        font-size: 24px;
        font-family: $font-regular;
        font-weight: normal;
        text-align: center;
    }

    &__header-subtitle {
        margin-top: 7px;
        font-size: 20px;
        font-family: $font-regular;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: $color-grey;
    }
}
