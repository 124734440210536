.c-projects {
    &__sidebar {
        @include mq(1px, medium) {
            @include add-entity-mobile;
        }

        @include mq(medium) {
            @include add-entity;
        }
    }

    &__sidebar-image {
        @include mq(1px, medium) {
            display: none;
        }

        @include mq(medium) {
            @include add-entity-image;
        }
    }

    &__sidebar-image-icon {
        @include add-entity-image-icon;
    }

    &__sidebar-text {
        @include mq(1px, medium) {
            display: none;
        }

        @include mq(medium) {
            @include add-entity-text;
        }
    }
}
