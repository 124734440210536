.c-project-entry {
    width: 100%;

    &--not-searched {
        display: none;
    }

    &__color {
        @include projects-colors();
    }
}
