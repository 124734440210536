.c-user-list {
    display: block;
    padding-bottom: $global-spacing-unit * 2;

    &__header {
        font-size: 18px;
        font-weight: normal;
        font-family: $font-bold;
        padding-bottom: $global-spacing-unit;
        margin-bottom: $global-spacing-unit;
        border-bottom: 1px solid $color-grey-light;
        text-align: center;
        text-transform: uppercase;

        @include mq(medium) {
            text-align: left;
        }
    }

    &__users-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include mq(medium) {
            text-align: center;
            width: 100%;
            justify-content: flex-start;
        }
    }

    &__summary {
        @include list-summary;
    }

    &__summary-quantity {
        @include list-summary-quantity;
    }
}
