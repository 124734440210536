.u-feedback {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    border: 1px solid $color-red;
    border-left-width: 0;
    border-bottom-width: 0;
    z-index: 9999;
    text-align: center;
    padding: 10px;
    background-color: $color-red;
    color: #fff;
    font-weight: bold;
    transition: $transition-default;

    &:hover {
        color: #ea4b35;
        background-color: rgba(255, 255, 255, 0.925);
    }
}
