.c-tasks-list {
    &__table {
        margin-top: $global-spacing-unit * 2;
        width: 100%;
        table-layout: fixed;
    }

    &__table-tr {
        border: 1px solid lighten($color-grey, 29);
        border-width: 1px 0 0 0;

        @each $property, $value in $projects-color-array {
            &--#{$property} {
                &:hover .c-tasks-list__table-td--project {
                    color: $value;
                }

                & .c-tasks-list__table-td--project:before {
                    background: $value;
                }
            }
        }
    }

    &__table-th {
        display: none;
        padding-top: 10px;
        line-height: 36px;
        font-size: 14px;
        font-weight: normal;
        opacity: 0.5;
        vertical-align: middle;
        padding-left: 30px;
        text-align: left;

        @include mq(small) {
            display: table-cell;
        }

        &--project {
            width: 25%;

            @include mq(medium) {
                text-align: right;
            }
        }

        &--task {
            width: 40%;
        }

        &--time {
            text-align: right;
            width: 20%;
        }

        &--users {
            width: 20%;
        }

        &--updated {
            opacity: 1;
            width: 15%;
        }
    }

    &__table-td {
        display: inline-block;
        height: $global-spacing-unit * 3;
        vertical-align: middle;
        padding-left: 30px;
        transition: $transition-default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq(1px, small) {
            width: 100%;
            height: 30px;
            line-height: 30px;
            text-align: left;
        }

        @include mq(small) {
            display: table-cell;
        }

        &--project {
            position: relative;
            color: $color-grey;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;

            &:before {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                bottom: 0;
                width: 6px;
                background: $color-grey;
            }

            @include mq(medium) {
                text-align: right;
                padding-left: 20px;
            }
        }

        &--time {
            font-size: 14px;
            color: $color-grey;

            @include mq(small) {
                font-size: 16px;
                text-align: right;
            }
        }

        &--users {
            font-size: 14px;
            color: $color-grey;
        }

        &--updated {
            font-size: 14px;
            color: $color-grey;
        }

        .c-tasks-list__table-tr:hover & {
            background: $color-grey-lighter;
        }
    }

    &__table-td-label {
        @include mq(small) {
            display: none;
        }
    }

    &__show-more {
        cursor: pointer;
        text-align: center;
        padding: $global-spacing-unit 0;
        border-top: 1px solid lighten($color-grey, 29);
        transition: $transition-default;

        &:hover {
            background: $color-grey-lighter;
        }
    }
}
