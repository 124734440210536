@mixin input() {
    @include appearance;
    display: block;
    width: 100%;
    height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $input-height-base - ($padding-base-vertical * 2);
    color: $input-color;
    background-color: #fff;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid $border-color-input;
    border-radius: 2px;
    box-shadow: 0;
    transition: $transition-default;
    font-family: $font-regular;

    &:focus {
        border-color: $input-focus-border-color;
        outline: 0;
        box-shadow: 0 2px 6px -1px rgba(45, 50, 53, 0.2);
    }

    // Placeholder
    @include placeholder;

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        border: 0;
        background-color: transparent;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        background-color: $input-disabled-bg-color;
        opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
    }

    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
    }

    &--error {
        border: 1px solid $border-color-input-error;

        &:focus {
            border: 1px solid $border-color-input-error;
        }
    }
}

@mixin thin-form {
    margin: 0 auto;
    width: 100%;
    max-width: 310px;
}

@mixin validation {
    &__validation {
        display: block;
        font-size: 14px;
        font-weight: normal;
        text-align: left;

        @include mq(small) {
            position: absolute;
            left: 100%;
            top: 0;
            margin: 0;
            width: 150px;
            height: 100%;
        }
    }

    &__validation-msg {
        display: block;
        position: relative;
        margin-left: 14px;
        padding-left: 30px;
        color: $color-grey;
        height: 25px;
        line-height: 25px;

        @include mq(small) {
            float: left;
            top: 50%;
            transform: translateY(-50%);
        }

        &--info {
            background: url($path-images + "/icon-check.svg") no-repeat left
                center;
        }

        &--error {
            background: url($path-images + "/icon-cross.svg") no-repeat left
                center;
        }

        &--warning {
            background: url($path-images + "/icon-warning.svg") no-repeat left
                center;
        }
    }
}

// Placeholder text
@mixin placeholder($color: $color-grey) {
    // Firefox
    &::-moz-placeholder {
        color: $color;
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder {
        color: $color;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: $color;
    } // Safari and Chrome
}

@mixin appearance($property: none) {
    -webkit-appearance: $property;
    -moz-appearance: $property;
    appearance: $property;
}
