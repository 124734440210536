.c-usage-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: $color-green;
    margin: $global-spacing-unit * 1.5 0 $global-spacing-unit * 2.5;

    &__quantity {
        display: flex;
        align-items: center;
        font-size: 48px;
        min-height: 70px;
        margin: 0 $global-spacing-unit/3;
    }

    &__quantity-holidays,
    &__quantity-sick {
        display: flex;
        align-items: center;
        border: 2px solid $color-green;
        padding: 0 $global-spacing-unit/2;
        min-height: 70px;

        svg {
            width: 32px;
            height: 32px;
            margin-left: $global-spacing-unit/4;
        }
    }

    &__quantity-holidays {
        border-right: none;
        border-radius: 4px 0 0 4px;

        svg {
            width: 24px;
            height: 24px;
            fill: $color-green;
        }
    }

    &__quantity-sick {
        color: $color-yellow;
        border-left: none;
        border-radius: 0 4px 4px 0;

        svg {
            fill: $color-yellow;
        }
    }

    &__label {
        @include mq($until: medium) {
            display: none;
        }
    }
}
