.c-open-activity {
    display: block;
    width: 100%;

    &__input {
        width: 100%;
        height: 50px;
        font-weight: $font-bold;
        text-align: center;
        border: 0;
        box-shadow: 0 5px 7px 1px rgba(41, 59, 77, 0.25);

        &:focus {
            outline: none;
        }
    }
}
