@mixin ts-project-color($color, $color-transparent) {
    &:after {
        content: "";
        position: absolute;
        background: $color;
        width: 6px;
        top: -2px;
        left: 0;
        bottom: 0;
    }

    &.c-ts-task--first-row {
        margin-top: 40px;

        &:after,
        &:before {
            top: -42px;
        }

        &:before {
            content: "";
            position: absolute;
            background: $color-transparent;
            height: 2px;
            left: 0;
            right: 0;
        }

        @include mq(large) {
            margin-top: 1px;

            &:after,
            &:before {
                top: -2px;
            }
        }
    }

    .c-ts-task__project-selector,
    .c-ts-task__project-mobile {
        color: $color;
    }
}

.c-ts-task {
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $border-color-default;
    height: 52px;
    line-height: 52px;
    width: 100%;

    &:hover {
        background-color: $color-task-row-hover;
    }

    &--project-red {
        @include ts-project-color($color-red, $color-red-transparent);
    }

    &--project-orange {
        @include ts-project-color($color-orange, $color-orange-transparent);
    }

    &--project-yellow {
        @include ts-project-color($color-yellow, $color-yellow-transparent);
    }

    &--project-green {
        @include ts-project-color($color-green, $color-green-transparent);
    }

    &--project-turquoise {
        @include ts-project-color(
            $color-turquoise-light,
            $color-turquoise-light-transparent
        );
    }

    &--project-blue {
        @include ts-project-color($color-blue, $color-blue-transparent);
    }

    &--project-violet {
        @include ts-project-color($color-violet, $color-violet-transparent);
    }

    &--project-junglegreen {
        @include ts-project-color(
            $color-jungle-green,
            $color-jungle-green-transparent
        );
    }

    &--project-cerulaenblue {
        @include ts-project-color(
            $color-cerulaen-blue,
            $color-cerulaen-blue-transparent
        );
    }

    &--project-beaverbrown {
        @include ts-project-color(
            $color-beaver-brown,
            $color-beaver-brown-transparent
        );
    }

    &--project-purpleheart {
        @include ts-project-color(
            $color-purple-heart,
            $color-purple-heart-transparent
        );
    }

    &--project-hotpink {
        @include ts-project-color($color-hot-pink, $color-hot-pink-transparent);
    }

    &__name-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        box-shadow: inset -8px 0 5px -5px rgba(0, 0, 0, 0.05);
        max-width: 75%;

        @include mq(small) {
            max-width: 30%;
        }
    }

    &__task-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-grow: 1;
        min-width: 0;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__task-container-name {
        min-width: 0;
        padding-right: 10px;
        flex: 0 1 auto;
        display: inline-flex;
        vertical-align: top;
    }

    &__task-container-name-txt {
        min-width: 0;
        flex: 0 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        cursor: default;
    }

    &__task-container-edit {
        cursor: pointer;
        margin-top: 18px;
        height: 16px;
        opacity: 0.5;
        transition: $transition-default;

        &:hover {
            opacity: 1;
        }

        &--svg {
            fill: $color-green;
            width: 16px;
            height: 16px;
            opacity: 1;
            cursor: default;
            margin-right: 8px;
        }
    }

    &__task-container-nonbillable {
        cursor: pointer;
        transition: $transition-default;
        margin-top: 20px;
        margin-right: 16px;
        height: 12px;

        svg {
            width: 12px;
            height: inherit;
            float: left;
            transition: $transition-default;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__project-selector {
        display: none;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        flex-grow: 0;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq(large) {
            display: block;
        }
    }

    &__project-mobile {
        display: none;

        .c-ts-task--first-row & {
            display: block;
            position: absolute;
            top: -40px;
            left: 20px;
            height: 40px;
            width: calc(125% - 20px);
            line-height: 40px;
            font-family: $font-bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include mq(large) {
                display: none;
            }
        }
    }

    &__summary {
        font-size: 14px;
        color: $color-summary;
        border-left: 1px solid $border-color-default;
        text-align: center;

        .c-ts-task:hover & {
            color: $color-blue-darker;
        }
    }

    &__task-start,
    &__task-stop {
        opacity: 0.2;
        line-height: 54px;
        transition: $transition-default;

        .c-ts-task:hover & {
            opacity: 1;
        }
    }

    &__task-start {
        display: none;

        .c-ts-task:hover & {
            display: block;
        }
    }

    &__wrapper-icon {
    }
}
