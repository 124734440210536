.c-add-holidays-modal {
    &__form {
        margin: 0 auto;
        text-align: center;
        max-width: 400px;
    }

    &__form-dropdown {
        position: relative;
        margin-bottom: $global-spacing-unit;
    }

    &__type-selector {
        padding: $global-spacing-unit 0;
        align-items: center;
    }
}
