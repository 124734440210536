@mixin alert($type) {
    margin-top: $global-spacing-unit;
    padding: $global-spacing-unit;
    border: 1px solid $border-color-default;
    border-radius: $border-radius-base;

    @if $type {
        &--#{$type} {
            padding-left: 70px;
            background: url($path-images + "/icon-" + $type + ".svg")
                20px
                center
                no-repeat;
            background-size: 30px;
        }
    }
}
