.c-send-reset-password-email {
    @include backdrop-header;

    &__form {
        margin: 0 auto;
        max-width: 310px;
        width: 100%;
    }

    &__form-btn {
        width: 100%;

        @include mq(tiny) {
            max-width: 150px;
        }
    }

    &__valid {
        color: $color-green;
    }

    &__invalid {
        color: $color-red;
    }
}
