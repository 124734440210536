@mixin toogle {
    &__wrapper-toggle {
        background-color: $backdrop-container-color;
        padding: 4px;
        display: inline-block;
        border-radius: $btn-border-radius-oval;
        margin-bottom: 21px;
        margin-top: 2px;
        align-self: start;
        flex-shrink: 0;

        @include mq($until: "medium") {
            background-color: $color-grey-v5;
            margin-top: 0;
            margin-bottom: 15px;
            margin-left: 12px;
        }
    }

    &__item-toggle {
        float: left;
        height: 22px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;

        &--selected {
            float: left;
            height: 22px;
            display: flex;
            align-items: center;
            padding-left: 12px;
            padding-right: 12px;
            background-color: $color-white;
            border-radius: $btn-border-radius-oval;
            box-shadow: 0px 1px rgba(39, 60, 78, 0.25);
            cursor: pointer;
            color: $color-blue-light;
        }
    }
}
