.c-seal-button {
    position: absolute;
    bottom: -16px;
    z-index: 2;
    width: 32px;
    margin-right: -16px;
    text-align: center;

    border: 2px solid $default-text-color;
    background: white;
    border-radius: 16px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:not(.c-seal-button--disabled):hover {
        border-color: $color-green;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
        width: 70px;
        margin-right: -35px;
    }

    &--animated {
        transition: $transition-default;
    }

    &--none {
        display: none;
    }

    &--before {
        left: -150px;
    }

    &--after {
        right: -150px;
    }

    &--1 {
        right: 65%;
    }

    &--2 {
        right: 55%;
    }

    &--3 {
        right: 45%;
    }

    &--4 {
        right: 35%;
    }

    &--5 {
        right: 25%;
    }

    &--6 {
        right: 17%;
    }

    &--7 {
        right: 11%;
    }
    &__icon {
        height: 18px;
        width: 18px;
        fill: $default-text-color;
        transition: $transition-default;
        .c-seal-button:not(.c-seal-button--disabled):hover & {
            fill: $color-green;
        }
    }

    &__navigate {
        transition: $transition-default;
        transform: scale(0, 0);
        cursor: pointer;
        height: 14px;
        width: 0;
        margin-left: 5px;
        &--prev {
            transform: scale(0, 0) rotate(180deg);
            margin-left: 0;
            margin-right: 5px;
        }
        .c-seal-button:not(.c-seal-button--disabled):hover & {
            width: 14px;
            transform: scale(1, 1);
            fill: $color-green;
            &--prev {
                transform: scale(1, 1) rotate(180deg);
            }
        }
    }
}
