$anim-duration: 0.5s !default;
$anim-timing: ease-in-out !default;
$bubbleCornerRadius: 10px !default;
$bubbleColor: #fff !default;
$bubbleBorderWidth: 5px !default;
$bubbleFontFamily: Helvetica, Arial !default;
$bubblePadding: 10px 15px !important;
$borderColor: rgba(0, 0, 0, 0.5) !default;
$circleWidth: 30px !default;
$bubbleZindex: 999999 !default;
