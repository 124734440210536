/*------------------------------------*\
  #PROJECT VARIABLES
\*------------------------------------*/

// Colors
$color-red: rgb(216, 87, 65);
$color-red-transparent: rgba(216, 87, 65, 0.3);

$color-orange: rgb(218, 131, 49);
$color-orange-transparent: rgba(218, 131, 49, 0.3);

$color-yellow: #edb91d;
$color-yellow-transparent: rgba(242, 197, 0, 0.3);

$color-violet: rgb(156, 86, 185);
$color-violet-transparent: rgba(156, 86, 185, 0.3);

$color-green: rgb(31, 206, 109);
$color-green-dark: #087a3c;
$color-green-transparent: rgba(44, 173, 135, 0.3);

$color-turquoise: rgb(98, 202, 119);
$color-turquoise-transparent: rgba(98, 202, 119, 0.3);

$color-blue: rgb(44, 150, 222);
$color-blue-transparent: rgba(44, 150, 222, 0.3);

$color-blue-light: #586d84;
$color-blue-lighter: #edeff1;
$color-blue-dark: #293b4d;
$color-blue-darker: #223140;

$color-beaver-brown: #8c6253;
$color-beaver-brown-transparent: rgba(140, 98, 83, 0.3);

$color-turquoise-light: #46d4d1;
$color-turquoise-light-transparent: rgba(70, 212, 209, 0.3);

$color-jungle-green: #2cad87;
$color-jungle-green-transparent: rgba(44, 173, 135, 0.3);

$color-cerulaen-blue: rgb(52, 89, 202);
$color-cerulaen-blue-transparent: rgba(52, 89, 202, 0.3);

$color-hot-pink: #ff6b9e;
$color-hot-pink-transparent: rgba(255, 107, 158, 0.3);

$color-purple-heart: #6b33ce;
$color-purple-heart-transparent: rgba(107, 51, 206, 0.3);

// $color-

$color-google: #d84f24;
$color-ms: #46a1ee;

$color-grey: #98a8b3;
$color-grey-v2: #8e9cac;
$color-grey-v3: rgba(51, 51, 51, 0.15);
$color-grey-v4: #f9fafb;
$color-grey-v5: #d1d4d8;
$color-grey-v6: #dfe1e4;
$color-grey-light: rgb(249, 250, 251);
$color-grey-lighter: #fbfcfc;
$color-white: #fff;

$color-disabled: #999;

$color-pagination: #bec4c9;

$color-task-row-hover: rgb(249, 250, 251);

$color-summary: $color-grey;

$color-today: rgb(240, 242, 245);

$color-placeholder: #b8bfc5;

$border-color-default: #e8ebed;
$border-color-calendar: #ccc;
$border-color-input: #d6dde1;
$border-color-input-error: $color-red;

$header-color-default: #f6f8f9;

$projects-color-array: (
    red: $color-red,
    orange: $color-orange,
    yellow: $color-yellow,
    violet: $color-violet,
    green: $color-green,
    turquoise: $color-turquoise-light,
    blue: $color-blue,
    beaverbrown: $color-beaver-brown,
    junglegreen: $color-jungle-green,
    cerulaenblue: $color-cerulaen-blue,
    purpleheart: $color-purple-heart,
    hotpink: $color-hot-pink,
);

// Colors - backdrop
$backdrop-color: #344556;
$backdrop-container-color: #eff1f3;

// Colors - input
$input-color: $color-blue-dark;
$input-disabled-bg-color: $color-grey-light;
$input-focus-border-color: $color-green;

// Colors - dropdown
$dropdown-color: $color-blue-dark;
$dropdown-placeholder-color: $color-grey;

// Colors - errorbar
$message-bg-error-color: $color-red;
$message-bg-warning-color: #ffbb33;

// Colors - filter
$filter-background: $header-color-default;
$filter-border: darken($header-color-default, 4.5);

// Colors - user
$user-link-bg-color: #ebeef0;

// Colors - Add task row (atr)
$atr-bg-color: $header-color-default;
$atr-border-default-color: $border-color-default;
$atr-border-focus-color: $color-green;
$atr-shadow-drop-up-color: rgba(41, 59, 77, 0.25);

// Colors - Project selector (ps)
$ps-border-default-color: $border-color-default;
$ps-text-default-color: $color-blue-darker;
$ps-selected-option-bg-color: #f6f8f9;

// Fonts
$font-regular: ProximaNovaSoft-Regular, Arial, sans-serif;
$font-bold: ProximaNovaSoft-Bold, Arial, sans-serif;

// Font sizes
$font-size-base: 16px;
$font-size-small: 14px;

// Line heights
$line-height-large: 1.3333333;
$line-height-small: 1.5;
$line-height-base: 1.5;
$line-height-computed: floor(($font-size-base * $line-height-base)) !default;

// Paddings
$padding-base-vertical: 10px;
$padding-base-horizontal: 15px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 30px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

// Widths
$width-menu: 114px;
$width-btn-header-small: $width-menu;
$width-btn-header-tiny: 60px;
$width-btn-add-mobile: $width-menu;
$width-header-mobile: 60px;
$width-layout: 1400px;

// Heights
$height-header: 108px;
$height-header-mobile: 60px;
$height-logo: 28px;
$height-btn-add-mobile: $height-header-mobile;
$height-message: 50px;

$input-height-base: (
    $line-height-computed + ($padding-base-vertical * 2)
) !default;

// Paths
$path-images: "../../images";
$path-fonts: "../../fonts";

//Transition
$transition-default: all 300ms ease;

// Box shadow
$box-shadow-default: 0 3px 5px 0 rgba(0, 0, 0, 0.25);

// Border radius
$border-radius-base: 2px;
$border-radius-large: 3px;
$border-radius-small: 1px;
$btn-border-radius-oval: 50px;

// Spacing
$global-spacing-unit: 20px;

// Main
$padding-right-main: $width-menu;
