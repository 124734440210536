@mixin sealed-background {
    background: fixed
        repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 3px,
            rgba($color-blue-darker, 0.2) 4px,
            transparent 5px
        );
}
