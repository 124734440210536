.c-project-report {
    padding-bottom: $global-spacing-unit * 3;

    &__container {
        display: none;
        width: 100%;
        padding: $global-spacing-unit 0 0;

        @include mq(medium) {
            display: block;
        }
    }

    &__chart-row {
        display: flex;
        position: relative;
        height: 65px;
        border-top: 1px solid lighten($color-grey, 29);
        cursor: pointer;

        &:last-of-type {
            border-bottom: 1px solid lighten($color-grey, 29);
        }

        @each $property, $value in $projects-color-array {
            &--#{$property} {
                &:hover .c-project-report__project-label-column--project {
                    color: $value;
                }

                & .c-project-report__project-label-column--project:before {
                    background: $value;
                }
            }
        }
    }

    &__project-label-column {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: $global-spacing-unit;
        text-align: right;
        cursor: pointer;

        &--project {
            color: $color-grey;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;

            &:before {
                content: "";
                position: absolute;
                top: -1px;
                left: 0;
                bottom: 0;
                width: 6px;
                background: $color-grey;
            }
        }
    }

    &__chart-scale-grid {
        width: 100%;
        position: relative;
        display: flex;
    }

    &__chart-scale-column {
        border-left: 1px dashed lighten($color-grey, 20%);
        width: 100%;

        &--last {
            width: 10%;
            flex-shrink: 0;
        }
    }

    &__project-bar {
        position: absolute;
        left: 0;
        height: 20px;
        transition: $transition-default;

        &--positive {
            top: 10px;
            background-color: rgba($color-green, 0.9);

            &:hover {
                background-color: darken($color-green, 10);
            }
        }

        &--negative {
            top: 35px;
            background-color: rgba($color-red, 0.9);

            &:hover {
                background-color: darken($color-red, 10);
            }
        }
    }

    &__header-row {
        display: flex;
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        opacity: 0.5;
    }

    &__chart-scale-head-row {
        display: flex;
        width: 100%;
    }

    &__chart-scale-head-column {
        width: 100%;

        &--last {
            width: 10%;
            flex-shrink: 0;
        }
    }

    &__mobile {
        display: table;
        table-layout: fixed;
        width: 100%;
        font-size: 12px;

        @include mq(tiny) {
            font-size: $font-size-base;
        }

        @include mq(medium) {
            display: none;
        }
    }

    &__mobile-row {
        &:not(:last-child) {
            border-bottom: 1px solid $border-color-default;
        }

        &--head {
            border-bottom: 2px solid $border-color-default;
        }

        > *:first-child {
            padding-left: $global-spacing-unit;

            @include mq(small) {
                padding-left: $global-spacing-unit * 3;
            }
        }

        > *:last-child {
            padding-right: $global-spacing-unit;

            @include mq(small) {
                padding-right: $global-spacing-unit * 3;
            }
        }

        > * {
            padding: $global-spacing-unit / 2 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @each $property, $value in $projects-color-array {
            &--#{$property} {
                .c-project-report__mobile-name {
                    color: $value;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
        }
    }

    &__mobile-name {
        width: 30%;
        text-align: left;

        @include mq(tiny) {
            width: 40%;
        }

        @include mq(small) {
            width: 60%;
        }
    }

    &__mobile-income {
        text-align: right;
    }

    &__mobile-expanses {
        text-align: right;
    }
}
