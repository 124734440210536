$btn-font-weight: normal;

$btn-default-color: $color-blue-dark;
$btn-default-bg: lighten($color-grey, 25%);
$btn-default-border: darken($btn-default-bg, 10%);

$btn-primary-color: #fff;
$btn-primary-bg: $color-green;
$btn-primary-border: darken($btn-primary-bg, 10%);

$btn-success-color: #fff;
$btn-success-bg: $color-green;
$btn-success-border: darken($btn-success-bg, 10%);

$btn-info-color: #fff;
$btn-info-bg: $color-blue;
$btn-info-border: darken($btn-info-bg, 10%);

$btn-warning-color: #fff;
$btn-warning-bg: $color-orange;
$btn-warning-border: darken($btn-warning-bg, 10%);

$btn-danger-color: #fff;
$btn-danger-bg: $color-red;
$btn-danger-border: darken($btn-danger-bg, 10%);

$btn-google-color: #fff;
$btn-google-bg: $color-google;
$btn-google-border: darken($btn-google-bg, 10%);

$btn-ms-color: #fff;
$btn-ms-bg: $color-ms;
$btn-ms-border: darken($color-ms, 10%);

$link-color: $color-blue-dark;
$link-hover-color: darken($link-color, 10%);
$link-hover-decoration: none;
$btn-link-disabled-color: $color-grey;

$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-large;
$btn-border-radius-small: $border-radius-small;
