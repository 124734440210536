.o-form {
    &__input {
        @include input;

        .o-form__input-group-addon + & {
            padding-left: 40px;
        }
    }

    &__radio {
        display: inline-block;
    }

    &__radio-input {
        display: none;
    }

    &__radio-label {
        position: relative;
        padding-left: $global-spacing-unit;

        &:before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            border: 2px solid #fff;
            border-radius: 50%;
            background: #fff;
            margin-top: -7px;
            top: 50%;
            left: 0;
            box-shadow: 0 0 0 2px $color-blue-dark;
            transition: $transition-default;
        }

        .o-form__radio-input:checked + & {
            &:before {
                background: $color-green;
            }
        }
    }

    &__group {
        margin-bottom: $global-spacing-unit;

        > .o-form__radio {
            display: block;

            + .o-form__radio {
                margin: $global-spacing-unit / 2 0 0;
            }
        }

        &--inline {
            > .o-form__radio {
                display: inline-block;

                + .o-form__radio {
                    margin: 0 0 0 $global-spacing-unit;
                }
            }
        }
    }

    &__input-group {
        position: relative;
    }

    &__input-group-addon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        text-align: center;
        line-height: 45px;
        opacity: 0.5;
        overflow: hidden;
    }

    &__label {
        text-align: left;
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        padding: 0 15px 5px;
    }

    &__group-error-msg {
        color: $color-red;
        padding: 10px 15px 0;
        text-align: left;
    }

    @include validation;
}
