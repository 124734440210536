div.hopscotch-bubble .hopscotch-nav-button {
    display: inline-block;
    width: auto;
    margin-bottom: 0;
    color: $color-blue-darker;
    font-weight: $btn-font-weight;
    font-family: $font-bold;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background: none;
    border: none;
    white-space: nowrap;
    @include user-select(none);
    @include button-size(
        $padding-small-vertical,
        $padding-small-horizontal,
        $font-size-small,
        $line-height-small,
        $btn-border-radius-small
    );
    height: auto;

    &:hover,
    &:focus,
    &--focus {
        color: $color-green;
        background: none;
        text-decoration: none;
        outline: 0 !important;
    }

    &:active,
    &--active {
        outline: 0 !important;
        background: none;
    }

    &--disabled,
    &[disabled] {
        background: none;
        cursor: not-allowed;
        opacity: 0.65;
        box-shadow: none;
    }
}
