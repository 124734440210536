.c-signup-invitation {
    @include backdrop-header;

    &__form {
        margin: 0 auto;
        max-width: 310px;
        width: 100%;
    }

    &__form-log-in {
        margin: ($global-spacing-unit / 2) 0 $global-spacing-unit;
        line-height: 1.5;
    }

    &__form-log-in-link {
        border-bottom: 1px solid $border-color-default;
    }

    &__login-error {
        margin-top: 10px;
        font-size: small;
        color: $color-red;
    }
}
