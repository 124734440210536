.c-timepot-sealer {
    display: block;
    position: relative;

    &__container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        min-width: 32px;
        border: 2px solid $color-white;
        border-radius: 16px;
        cursor: pointer;
    }

    &__label {
        margin: 0;
        max-width: 0;
        transform: scale(0);
        transition: $transition-default;
        overflow: hidden;

        .c-timepot-sealer__container:hover &,
        .c-timepot-sealer__container--active & {
            margin: 0 5px;
            max-width: 100px;
            transform: scale(1);
        }
    }

    &__icon {
        height: 18px;
        width: 18px;
        fill: $color-white;
    }

    &__flyout {
        position: absolute;
        right: 0;
        width: 338px;
        background: white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
        top: calc(100% + 2px);
        padding: $global-spacing-unit/2;
    }

    &__title {
        color: $default-text-color;
        font-size: 16px;
        font-weight: bold;
        padding: $global-spacing-unit/2;
    }

    &__explanation {
        color: #98a8b3;
        font-size: 12px;
        line-height: 14px;
        border-top: 1px solid $border-color-default;
        padding: $global-spacing-unit/2 0;
    }

    &__button-group {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }
}
