.c-add-task {
    &__container {
        max-width: 400px;
        margin: 0 auto;
        text-align: center;
    }

    &__container-input {
        position: relative;
        margin-bottom: 15px;
    }
}
