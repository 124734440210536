/*------------------------------------*\
  #SHAME.CSS
\*------------------------------------*/

.u-hidden {
    display: none !important;
}

// ------------------------------------------------------------------------------------------------------------------ //
// Form

.u-form-group {
    position: relative;
    margin-bottom: $global-spacing-unit;

    &--bigger-margin-bottom {
        margin-bottom: $global-spacing-unit * 3 / 2;
    }
}

.u-form-control {
    @include input;
}

// ------------------------------------------------------------------------------------------------------------------ //
// Date picker

.u-table-condensed > thead > tr > th,
.u-table-condensed > tbody > tr > th,
.u-table-condensed > tfoot > tr > th,
.u-table-condensed > thead > tr > td,
.u-table-condensed > tbody > tr > td,
.u-table-condensed > tfoot > tr > td {
    padding: 3px;
}

// ------------------------------------------------------------------------------------------------------------------ //
// Perfect scroll bar

.ps-scrollbar-x-rail,
.ps-scrollbar-y-rail {
    z-index: 5000;
}

// ------------------------------------------------------------------------------------------------------------------ //
// Image crop component

.cr-boundary {
    width: 100% !important;
}
