$color-picker-breakpoint-large: 630px;
$color-picker-breakpoint-medium: 400px;

.c-color-picker {
    margin: auto;
    display: inline-block;
    z-index: 34;
    padding: 6px 6px 2px;
    background: #eff1f3;
    border-radius: 2px;

    @include mq($until: $color-picker-breakpoint-large) {
        width: 279px;
    }

    @include mq($until: $color-picker-breakpoint-medium) {
        width: 189px;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__list-item {
        display: inline-block;
        margin: 0 3px 0 0;

        &:last-of-type {
            margin-right: 0;
        }

        @include mq($until: $color-picker-breakpoint-large) {
            &:nth-child(6) {
                margin-right: 0;
            }
        }

        @include mq($until: $color-picker-breakpoint-medium) {
            &:nth-child(6) {
                margin-right: 3px;
            }

            &:nth-child(4),
            &:nth-child(8) {
                margin-right: 0;
            }
        }
    }

    &__color {
        display: block;
        cursor: pointer;
        width: 42px;
        height: 42px;
        border-radius: 2px;
        transition: all 300ms ease;

        &--picked {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url($path-images + "/icon-check.png") no-repeat
                    center center;
            }
        }

        @include projects-colors();
    }
}
