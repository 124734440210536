.c-project-summary {
    &__container {
        width: 100%;
        margin: $global-spacing-unit * 3 0 0;
        text-align: center;
    }

    &__label {
        display: block;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: $global-spacing-unit / 2;
    }

    &__sublabel {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        margin-right: $global-spacing-unit / 2;
        margin-left: $global-spacing-unit / 2;
    }

    &__expenses {
        color: $color-red;
    }

    &__income {
        color: $color-green;
    }
}
