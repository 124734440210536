$calendar-breakpoint-medium: 1140px;
$calendar-breakpoint-large: 1360px;

.c-month {
    display: block;
    width: 167px;
    height: 170px;
    margin: 0 14px 32px 14px;

    @include mq($calendar-breakpoint-medium, $calendar-breakpoint-large) {
        &:nth-of-type(n + 5) {
            order: 1;
        }

        &:nth-of-type(n + 9) {
            order: 2;
        }
    }

    @include mq($calendar-breakpoint-large) {
        &:nth-of-type(n + 7) {
            order: 1;
        }
    }

    &__name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        padding: 0 8px 10px 4px;
        font-size: 20px;
        line-height: 24px;
        transition: $transition-default;

        &:after {
            content: "";
            position: absolute;
            border: 1px solid $color-green;
            top: -5px;
            left: -10px;
            right: -10px;
            bottom: 5px;
            border-radius: 20px;
            opacity: 0;
            transition: $transition-default;
        }

        svg {
            fill: $color-green;
            transition: $transition-default;
            width: 10px;
            height: 10px;
            opacity: 0;
        }

        &:hover {
            color: $color-green;

            &:after {
                opacity: 1;
            }

            svg {
                opacity: 1;
            }
        }
    }

    &__header {
        margin-bottom: 8px;
    }

    &__header-day {
        font-size: 12px;
        text-align: center;
        font-family: $font-bold;
        color: $color-grey;
    }
}
