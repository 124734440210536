/*------------------------------------*\
  #NAVBAR
\*------------------------------------*/

.c-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;

    &--active {
        z-index: 55;
    }

    @include mq(medium) {
        position: static;
    }

    &__header {
        display: block;
        width: 100%;
        height: $height-header-mobile;
        background-color: $color-blue-dark;
        position: relative;
        text-align: center;

        @include mq(medium) {
            display: none;
        }
    }

    &__header-btn-menu {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: $width-btn-header-tiny;
        height: $height-header-mobile;
        transition: $transition-default;
    }

    &__header-btn-menu-line {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -9px;
        width: 20px;
        height: 2px;
        background-color: #fff;
        transition: $transition-default;

        &:after,
        &:before {
            content: "";
            top: 50%;
            left: 50%;
            margin-left: -10px;
            position: absolute;
            width: 20px;
            height: 2px;
            background-color: #fff;
            transition: $transition-default;
        }

        &:after {
            margin-top: -8px;
        }

        &:before {
            margin-top: 6px;
        }

        .c-navbar--active & {
            background-color: $color-blue-dark;

            &:after {
                margin-top: 0;
                transform: rotate(45deg);
            }

            &:before {
                margin-top: 0;
                transform: rotate(-45deg);
            }
        }
    }

    &__nav {
        display: flex;
        flex-flow: column;
        top: $height-header-mobile;
        right: (($width-menu * 3) + 6px) * -1;
        bottom: 0;
        height: auto;
        width: $width-menu;
        background-color: $color-blue-dark;
        z-index: 100;
        transition: $transition-default;

        .c-navbar--active & {
            right: 0;
        }

        @include mq(1px, medium) {
            position: fixed;
            top: 0;
            width: $width-menu * 3;
            max-width: calc(100% - #{$width-header-mobile});
            overflow-x: auto;

            .c-navbar--active & {
                box-shadow: -3px 0 6px rgba(0, 0, 0, 0.16),
                    -3px 0 6px rgba(0, 0, 0, 0.23);
            }
        }

        @include mq(medium) {
            position: fixed;
            top: 0;
            right: 0;
            height: 100%;
            background-color: $color-blue-dark;

            .c-timepot--message & {
                top: $height-message;
            }
        }
    }

    &__nav-menu {
        margin: 0;
        padding: 15px 0 0 0;
        list-style: none;

        @include mq(1px, medium) {
            &--other {
                border-top: 1px solid $color-blue-light;
                margin-top: 15px;
                padding-top: 15px;
            }
        }

        @include mq(medium) {
            flex-grow: 1;

            &--other {
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }

    @include navbar-item("__nav-logout-btn");
    @include navbar-item("__nav-feedback");

    &__nav-logout-btn,
    &__nav-feedback {
        height: 60px;

        svg {
            max-width: 24px;
            max-height: 24px;
        }
    }

    &__overflow {
        @include mq(1px, medium) {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            background-color: rgba($color-blue-dark, 0.85);

            .c-navbar--active & {
                display: block;
            }
        }
    }
}
