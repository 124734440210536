@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;
    border-width: 0 0 1px 0;
    box-shadow: 0 1px 2px -1px $border;

    &:hover {
        color: $color;
        background-color: $background;
    }

    &:active,
    &:focus {
        color: $color;
        background-color: $background;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }

    &--disabled,
    &[disabled] {
        &:hover,
        &:focus {
            background-color: $background;
            border-color: $border;
        }
    }
}

// Button sizes
@mixin button-size(
    $padding-vertical,
    $padding-horizontal,
    $font-size,
    $line-height,
    $border-radius
) {
    padding: $padding-vertical $padding-horizontal ($padding-vertical - 3);
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

// User select
// For selecting text on the page

@mixin user-select($select) {
    -webkit-user-select: $select;
    -moz-user-select: $select;
    -ms-user-select: $select; // IE10+
    user-select: $select;
}
