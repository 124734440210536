.c-barchart-horizontal {
    display: block;
    position: relative;
    margin: 0 auto;
    padding-bottom: $global-spacing-unit * 2;
    width: 100%;
    max-width: 840px;

    &__header {
        display: flex;
        color: $color-grey;
        font-size: 14px;
        margin-bottom: $global-spacing-unit;
    }

    &__header-name {
        width: 180px;
        text-align: right;
    }

    &__header-unit {
        flex-grow: 1;
        padding-left: 40px;
    }

    &__container {
        display: flex;
    }

    &__container-labels {
        width: 180px;
    }

    &__container-labels-element {
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        text-align: right;
        text-transform: uppercase;
        font-weight: bold;
        color: $color-grey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__container-bars {
        flex-grow: 1;
        padding-left: 30px;
        padding-right: 130px;
    }

    &__container-bar-element {
        position: relative;
        height: 36px;
    }

    &__bar {
        position: relative;
        width: auto;
        height: 36px;
    }

    &__bar-inner {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 6px;
        min-width: 5px;
        background: $color-blue;
        border-radius: 3px;
        transition: $transition-default;

        .c-barchart-horizontal__container-bar-element--hover & {
            height: 12px;
            box-shadow: 0 2px 4px 0 rgba(45, 50, 53, 0.2);
        }
    }

    &__bar-value {
        position: absolute;
        right: -110px;
        top: 0;
        display: block;
        padding: 0 0 0 14px;
        width: 110px;
        font-size: 14px;
        line-height: 36px;
        color: $color-blue-dark;
        text-align: left;
    }
}
