.o-date-picker {
    display: flex;
    flex-direction: column;
    width: 340px;
    margin: 0 auto;

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        color: $color-blue-dark;
        text-align: center;
    }

    &__header-label {
        flex: 1;
        font-size: 20px;
    }

    &__header-nav {
        width: calc(100% / 7);
        font-size: 28px;
        cursor: pointer;
    }

    &__month {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: $global-spacing-unit/2 0;
    }

    &__month-header,
    &__month-week {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        min-height: 20px;
        margin: 3px 0;
    }

    &__month-header-day,
    &__month-week-day {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__month-header-day {
        font-size: 12px;
        font-weight: bold;
        color: $color-grey;
        cursor: default;
    }

    &__month-week-day {
        font-size: 12px;
        color: $color-blue-dark;
        cursor: pointer;

        &--weekend {
            color: $color-grey;
        }

        &--today {
            font-weight: bold;
        }

        &--selected {
            font-weight: bold;

            .o-date-picker__month-week-day--in-range + & {
                border-radius: 0 10px 10px 0;
            }
        }

        &--sealed {
            @include sealed-background;
        }

        &--in-range {
            font-weight: bold;
            color: $color-white;
            background-color: $color-blue;

            .o-date-picker__month-week-day:not(
                    .o-date-picker__month-week-day--in-range
                )
                + & {
                border-radius: 10px 0 0 10px;

                &.o-date-picker__month-week-day--last-in-range {
                    border-radius: 10px;
                }
            }
        }

        &--first-in-range {
            border-radius: 10px 0 0 10px;
        }

        &--last-in-range {
            border-radius: 0 10px 10px 0;
        }
    }

    &__month-week-day-label {
        display: block;
        width: 100%;
        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        padding: 0 2px;

        .o-date-picker__month-week-day--today & {
            box-shadow: inset 0 0 0 1px $color-blue-dark;
        }

        .o-date-picker__month-week-day--selected & {
            color: $color-white;
            background-color: $color-green;
        }

        .o-date-picker__month-week-day:hover & {
            color: $color-white;
            background-color: $color-blue-dark;
        }
    }
}
