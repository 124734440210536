.c-timer-start {
    cursor: default;

    &__btn {
        display: inline-block;
        border: none;
        width: 45px;
        height: 51px;
        padding: 0 15px;
        cursor: pointer;
        background: transparent url($path-images + "/icons/play.svg") center
            no-repeat;
        background-size: 15px;

        .c-timer-start--disabled {
            cursor: default;
            opacity: 0.2;
        }
    }
}
