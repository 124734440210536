.c-donutchart {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    padding-bottom: $global-spacing-unit * 2;
    width: 100%;

    @include mq(medium) {
        flex-direction: row;
    }

    &__container {
        order: 1;
        position: relative;
        width: 100%;
        min-height: 320px;

        @include mq(medium) {
            order: 2;
            width: 350px;
            flex-shrink: 0;
        }

        @include mq(medium, large) {
            width: 25%;
        }
    }

    &__donut-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -10px 0 0 -100px;
        transform: translateY(-50%);
        z-index: 100;
        width: 200px;
        height: auto;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        @include mq(medium, large) {
            font-size: 14px;
        }

        @include mq(large) {
            font-size: 20px;
        }
    }

    &__labels {
        width: 100%;
        padding: 0 $global-spacing-unit;

        &--left {
            order: 2;

            @include mq(medium) {
                text-align: right;
                order: 1;
            }
        }

        &--right {
            order: 3;
        }
    }

    &__labels-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__labels-list-element {
        display: inline-flex;
        padding: 0 10px;
        height: 24px;
        line-height: 24px;
        background: #fff;
        color: $color-blue-dark;
        font-size: 14px;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba($color-grey, 0.45);
        width: 100%;
        margin: ($global-spacing-unit / 2) 0;
        cursor: pointer;

        @include mq(medium) {
            max-width: 280px;
            width: auto;
        }
    }

    &__labels-list-element-name {
        font-size: 12px;
        text-transform: uppercase;
        font-family: $font-bold;
        min-width: 0;
        flex: 0 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .c-donutchart__labels--left & {
            @include mq(medium) {
                order: 1;
            }
        }
    }

    &__labels-list-element-value {
        display: inline-block;
        padding: 0 14px 0 6px;
        width: 130px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .c-donutchart__labels--left & {
            @include mq(medium) {
                padding: 0 6px 0 14px;
                text-align: right;
                order: 2;
            }
        }
    }
}
