.c-video-modal {
    &__container {
        position: relative;
        margin: -80px -20px -60px;

        @include mq(small) {
            margin: -80px -40px -60px;
        }

        &:before {
            content: "";
            display: block;
            padding-top: 56.3%;
        }
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}
