$absent-height: 24px;
$absent-margin-bottom: 4px;
$absence-per-day: 3;
$week-border-width: 1;
$week-min-height: ($absence-per-day + 1) *
    ($absent-height + $absent-margin-bottom) +
    ($global-spacing-unit - $absent-margin-bottom) + $week-border-width;

.c-month-full {
    display: flex;
    flex-direction: column;
    margin: $global-spacing-unit 2 * $global-spacing-unit 2 *
        $global-spacing-unit;

    &--week-view {
        //min-height: 50px + 5 * $week-min-height;
    }

    &__header,
    &__week-legend,
    &__week {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 50px;
        transition: $transition-default;
    }

    &__header {
        justify-content: center;
    }

    &__header-title {
        font-size: 28px;
    }

    &__week-legend {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-grey;
        border-bottom: 1px solid $border-color-default;
    }

    &__week {
        align-items: stretch;
        min-height: $week-min-height;
        border-bottom: 1px solid $border-color-default;
        overflow: hidden;

        &:hover {
            background-color: $header-color-default;

            .c-month-full--week-view &,
            .c-month-full--hovered & {
                background-color: transparent;
            }
        }

        .c-month-full--week-view & {
            flex: 1;
        }
    }

    &__week-legend-day,
    &__day {
        flex-shrink: 0;
        width: calc(100% / 7);
        padding: $global-spacing-unit/2;
    }

    &__day {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        transition: $transition-default;
        padding-bottom: $global-spacing-unit/2 - 4px;

        &--today:not(.c-month-full__day--other-month) {
            background-color: $color-today;
        }

        &--other-month {
            > * {
                display: none;
            }
        }

        .c-month-full--week-view:not(.c-month-full--hovered) &:hover {
            background-color: $header-color-default;
        }
    }

    &__day-label {
        flex-shrink: 0;
        width: 25px;

        .c-month-full__day--sat &,
        .c-month-full__day--sun & {
            opacity: 0.5;
        }
    }

    &__absence {
        flex: 1;
        width: calc(100% - 25px);
    }

    &__absent,
    &__absent-more {
        display: flex;
        align-items: center;
        min-height: 24px;
        margin-bottom: 4px;
        font-size: 11px;
        background-color: rgba($color-blue, 0.25);
        border-radius: 8px;
        transition: $transition-default;

        &--me {
            background-color: rgba($color-green, 0.25);
        }

        &--sick {
            background-color: rgba($color-yellow, 0.25);
        }

        .c-month-full--hovered & {
            opacity: 0.33;

            &--hover {
                opacity: 1;
            }
        }
    }

    &__absent {
        cursor: default;

        &:nth-child(n + #{$absence-per-day + 1}) {
            display: none;
        }

        .c-month-full--week-view & {
            &:nth-child(n + #{$absence-per-day + 1}) {
                display: flex;
            }
        }
    }

    &__absent-more {
        cursor: pointer;
        padding-left: 24px;
        background-color: $color-white;
        box-shadow: inset 0 0 0 1px $border-color-default;

        .c-month-full__week--visible & {
            display: none;
        }

        .c-month-full--hovered & {
            box-shadow: inset 0 0 0 1px rgba($color-blue, 0.25);
        }
    }

    &__absent-photo {
        flex-shrink: 0;
        position: relative;
        margin: 4px;
        width: 16px;
        height: 16px;
        border: 1px solid $color-white;
        border-radius: 50%;
        background: $color-grey-light url($path-images + "/icons/user.svg")
            center no-repeat;
        background-size: 100%;
        transition: $transition-default;
    }

    &__absent-photo-image {
        object-fit: cover;
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }

    &__absent-user {
        flex: 1;
        min-width: 0;
        margin-right: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
