$generic-dropdown-input-height: $input-height-base;
$generic-dropdown-disabled: $header-color-default;
$generic-dropdown-option-hover: $header-color-default;
$generic-dropdown-option-selected: darken($header-color-default, 3);

.c-generic-dropdown {
    display: block;
    position: relative;
    min-height: $generic-dropdown-input-height;
    text-align: left;

    &--contained {
        position: static;
    }

    &__activator {
        position: relative;
        cursor: pointer;
        transition: $transition-default;

        .c-generic-dropdown:not(.c-generic-dropdown--contained) & {
            @include input;
            color: $dropdown-color;

            &--disabled {
                background-color: $generic-dropdown-disabled;
                cursor: default;
            }
        }

        .c-generic-dropdown.c-generic-dropdown--contained & {
            @include mq(1px, medium) {
                @include input;
                color: $dropdown-color;
                border-radius: 0;
                padding-left: $global-spacing-unit;
            }

            @include mq(medium) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding: 0 $global-spacing-unit;

                &--disabled {
                    background-color: $generic-dropdown-disabled;
                    cursor: default;
                }
            }
        }

        &--paged {
            .c-generic-dropdown.c-generic-dropdown--contained & {
                background: #fff url($path-images + "/icon-search.png")
                    no-repeat 19px center;
                padding-left: 40px;
            }
        }
    }

    &__activator-selected {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;

        @include mq(small) {
            width: 100%;
        }
    }

    &__activator-placeholder {
        color: $dropdown-placeholder-color;
    }

    &__activator-icon {
        position: absolute;
        top: 10px;
        right: 15px;

        .c-generic-dropdown.c-generic-dropdown--contained & {
            @include mq(medium) {
                position: relative;
                top: auto;
                right: auto;
            }
        }

        .c-generic-dropdown.c-generic-dropdown--contained
            .c-generic-dropdown__activator--paged
            & {
            display: none;
        }
    }

    &__dropdown {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__input {
        @include appearance;
        position: absolute;
        width: 100%;
        height: 100%;
        height: calc(100% + 1px);
        top: 0;
        left: 0;
        right: 0;
        bottom: -1px;
        padding: 0 ($global-spacing-unit * 2) 0 $global-spacing-unit;
        box-shadow: 0 5px 7px 1px rgba($color-blue-dark, 0.25);
        z-index: 110;
        border: 0;
        outline: 0;
        font-family: $font-regular;
        font-weight: bold;
        color: $color-blue-dark;
        border-bottom: 1px solid $border-color-input;

        .c-generic-dropdown:not(.c-generic-dropdown--contained) & {
            border: 1px solid $border-color-input;
        }

        .c-generic-dropdown.c-generic-dropdown--contained & {
            @include mq(1px, medium) {
                border: 1px solid $border-color-input;
            }
        }

        &--error {
            color: $color-red;
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -15px;
        height: 30px;
        width: 30px;
        z-index: 120;

        @include loader(24px);
    }

    &__list-container {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        right: 0;
        margin: 0;
        z-index: 111;
        background: #fff;
        box-shadow: 0 5px 7px 1px rgba($color-blue-dark, 0.25);
    }

    &__list {
        list-style: none;
        max-height: 260px;
        overflow-y: auto;
        margin: 0;

        &--paged {
            max-height: none;
        }
    }

    &__list-element {
        @include dropdown-projects-colors;
    }

    &__option {
        display: block;
        cursor: pointer;
        color: $color-blue-dark;
        height: 36px;
        line-height: 36px;
        padding: 0 $global-spacing-unit;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        outline: 0;

        &:hover {
            background: $generic-dropdown-option-hover;
        }

        &--selected {
            background: $generic-dropdown-option-selected;
        }

        .c-generic-dropdown__list-element--projects & {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__close {
        position: absolute;
        display: block;
        cursor: pointer;
        top: 0;
        bottom: 0;
        right: 0;
        width: 33px;
        z-index: 111;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: 1px;
            width: 12px;
            height: 1px;
            background: $color-blue-dark;
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__pagination {
        position: relative;
        font-size: 60%;
        line-height: 25px;
        overflow: visible;
        text-align: center;
    }

    &__pagination-btn {
        display: block;
        color: $color-blue-dark;
        cursor: pointer;

        &:hover {
            background-color: $generic-dropdown-option-hover;
        }

        &--inactive {
            opacity: 0.5;
            cursor: default;
        }
    }

    &__pagination-desc {
        color: $color-grey;

        &--left {
            float: left;
            margin-left: 10px;
        }

        &--right {
            float: right;
            margin-right: 10px;
        }
    }

    &__toggle {
        display: flex;
        align-items: stretch;
        padding: 4px;
        margin: 5px;
        font-size: 14px;
        color: $default-text-color;
        background-color: $backdrop-container-color;
        border-radius: $btn-border-radius-oval;
    }

    &__toggle-item {
        flex: 1;
        min-height: 22px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;
        text-align: center;

        &--selected {
            background-color: $color-white;
            border-radius: $btn-border-radius-oval;
            box-shadow: 0 1px rgba(39, 60, 78, 0.25);
            color: $color-blue-light;
        }
    }
}
